import { Popconfirm, Progress } from "antd";
import Typography from "../Typography";
import "./GlobalUploader.scss";
import { CloseCircleFilled } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import { useEffect, useState } from "react";
import {
  API_ENDPOINT_STORAGE_UPLOAD_OBJECT_COMPLETE_UPLOAD,
  API_ENDPOINT_STORAGE_UPLOAD_OBJECT_CREATE_MULTIPART,
  API_ENDPOINT_STORAGE_UPLOAD_OBJECT_UPLOAD_PART,
} from "app/scenes/FinkAnalytics/finkanalytics.constants";
import { apiPost } from "app/services/apiServices";
import { uploadInfo, userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
export default function GlobalUploader(props: any) {
  const [uploadProgress, setUploadProgress] = useState(0.0);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [uploaderInfo, setUploadInfo] = useRecoilState<any>(uploadInfo);

  const arrayBufferToBase64 = (buffer: any) => {
    // ArrayBuffer to Base64 conversion
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleFileUpload = async () => {
    console.log("upload info:", uploaderInfo);

    if (!uploaderInfo) {
      return;
    }

    const uploadPartSize = 10 * 1024 * 1024; // Use larger part size like 10MB
    let start = 0;
    const fileKey = `${userDetails?.currentWorkspace?.id}/${uploaderInfo.name}`;
    const numParts = Math.ceil(uploaderInfo.size / uploadPartSize);
    let uploadId;

    try {
      const createMultipartUploadRes = await apiPost(
        API_ENDPOINT_STORAGE_UPLOAD_OBJECT_CREATE_MULTIPART,
        {
          fileName: uploaderInfo.name,
          fileType: uploaderInfo.type,
        }
      );

      uploadId = createMultipartUploadRes.data.UploadId;

      const uploadedParts = [];
      while (start < uploaderInfo.size) {
        const batchSize = Math.min(5, numParts - uploadedParts.length); // Upload 5 parts concurrently
        const promises = [];

        for (let i = 0; i < batchSize && start < uploaderInfo.size; i++) {
          const end = Math.min(start + uploadPartSize, uploaderInfo.size);
          const chunk = uploaderInfo.slice(start, end);

          const arrayBuffer = await chunk.arrayBuffer();
          const chunkBase64 = arrayBufferToBase64(arrayBuffer);
          const partNumber: any = uploadedParts.length + 1;

          const uploadPartPromise = await apiPost(
            API_ENDPOINT_STORAGE_UPLOAD_OBJECT_UPLOAD_PART,
            {
              Bucket: "raw-booking-data",
              Key: fileKey,
              PartNumber: partNumber,
              UploadId: uploadId,
              PartBuffer: chunkBase64,
            }
          );
          uploadedParts.push({
            ETag: uploadPartPromise.data.ETag,
            PartNumber: partNumber,
          });
          setUploadProgress((start / uploaderInfo.size) * 100); // Update Progress

          promises.push(uploadPartPromise);
          start = end;
        }

        await Promise.all(promises);
      }

      const completeMultipartUploadRes = await apiPost(
        API_ENDPOINT_STORAGE_UPLOAD_OBJECT_COMPLETE_UPLOAD,
        {
          Bucket: "raw-booking-data",
          Key: fileKey,
          UploadId: uploadId,
          Parts: uploadedParts,
        }
      );

      alert("Upload successful!");
      console.log("Final uploaded data:", completeMultipartUploadRes.data);
    } catch (err) {
      console.error("Error during multipart upload:", err);
    }
  };

  useEffect(() => {
    handleFileUpload();
  }, [uploaderInfo]);
  const handleCancelUpload = () => {
    setUploadInfo(null);
  };
  return !uploaderInfo ? null : (
    <div className="GlobalUploader">
      <div className="Left">
        <Typography style={{ color: colorPicker("neutral.800") }} weight={500}>
          {uploaderInfo?.name}
        </Typography>
        <Progress
          percent={parseFloat(uploadProgress?.toFixed(2))}
          status="active"
          strokeColor={colorPicker("primary.700")}
        />
      </div>
      <div className="Right">
        <Popconfirm
          title="Cancel Upload"
          description="Are you sure want to cancel this upload"
          onConfirm={handleCancelUpload}
          onCancel={() => null}
          okText="Yes"
          cancelText="No"
        >
          <CloseCircleFilled
            style={{ color: colorPicker("red.700"), cursor: "pointer" }}
          />
        </Popconfirm>
      </div>
    </div>
  );
}
