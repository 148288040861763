import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import { AgGridReact } from "ag-grid-react";
import { AgTableClient } from "app/shared/AgTable";
import { Button } from "antd";
import FinkAI from "app/shared/FinkAI";
import SpeechToText from "app/shared/FinkAI/SpeechToText";
import jsonData from "./data.json";

export default function FinkTest(props: any) {
  const [activeKey, setActiveKey] = useState(1);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const gridRef = useRef<any>();
  const PriceRenderer = (props: any) => {
    return <span>${props.value.toLocaleString()}</span>;
  };

  const MileageRenderer = (props: any) => {
    return <span>{props.value.toLocaleString()} miles</span>;
  };

  const columnDefs = [
    {
      field: "Vendor Name",
      headerName: "Vendor Name",
      minWidth: 200,
      sortable: true,
      filter: true,
      editable: true,
      enableRowGroup: true,
      expanded: false,
      cellRendererFramework: (params: any) => (
        <button>{params.data.expanded ? "-" : "+"}</button>
      ),
    },
    {
      field: "Claimable Status",
      headerName: "Claimable Status",
      minWidth: 200,
      sortable: true,
      filter: "agTextColumnFilter",
      editable: true,
      enableRowGroup: true,
    },

    {
      field: "Note Type",
      headerName: "Note Type",
      minWidth: 200,
      sortable: true,
      filter: true,
      editable: true,
      enableRowGroup: true,
    },
    {
      field: "Type",
      headerName: "Type",
      minWidth: 200,
      sortable: true,
      filter: true,
      editable: true,
      enableRowGroup: true,
    },
    {
      field: "Invoice-Total_GST",
      headerName: "Total GST Amount",
      minWidth: 200,
      sortable: true,
      editable: true,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "Invoice-Total_Amount",
      headerName: "Total Invoice Amount",
      minWidth: 200,
      sortable: true,
      editable: true,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
  ];

  const defaultActions = [
    {
      name: "Show Unclaimed Transactions",
      model: {
        groupModel: [],
        filterModel: {
          "Claimable Status": {
            type: "equals",
            filter: "Unclaimable",
          },
        },
        sortModel: [],
      },
    },
    {
      name: "Show Claimed Transactions",
      model: {
        groupModel: [],
        filterModel: {
          "Claimable Status": {
            type: "equals",
            filter: "Claimable",
          },
        },
        sortModel: [],
      },
    },
  ];

  const applySortingAndGrouping = (models: any) => {
    let jsonmodel = {
      groupModel: [
        {
          colId: "year",
          hide: true, // Optionally hide the group column in the main grid
        },
      ],
      filterModel: {},
      sortModel: [],
    };

    gridRef.current.setGroupModel(models.groupModel);

    gridRef.current.setFilterModel(models.filterModel);
    gridRef.current.applyColumnState(models.sortModel);
    // gridRef.current.setSortModel(models.sortModel);

    console.log("models are", models);

    // If you need to expand groups programmatically after setting filters
    // gridRef.current.forEachNode((node: any) => {
    //   node.setExpanded(true);
    // });
  };

  return (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <div style={{ height: 600 }}>
          <AgTableClient
            // @ts-ignore
            rowData={jsonData}
            ref={gridRef}
            groupPanel
            columnDefs={columnDefs}
          />
        </div>
      </div>
      <FinkAI
        onResponse={applySortingAndGrouping}
        defaultActions={defaultActions}
      />
    </div>
  );
}
