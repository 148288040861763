import PageHeader from "app/shared/PageHeader";
import React, { useEffect, useRef, useState } from "react";
import "./SchemaSourceDetail.scss";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, List, message } from "antd";
import { API_ENDPOINT_FETCH_DB_TABLES } from "app/scenes/FinkCRM/fink.crm.constants";
import { apiGet } from "app/services/apiServices";
import Loader from "app/shared/Loader";
import EmptyState from "app/shared/EmptyState";
import { AgTableClient } from "app/shared/AgTable";
import { DatabaseOutlined } from "@ant-design/icons";
import SearchInput from "app/shared/SearchInput";

export default function SchemaSourceDetail(props: any) {
  const [sourceData, setSourceData] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState("");
  const [zaId, setZAId] = useState("");
  let { sourceId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const gridRef: any = useRef();
  useEffect(() => {
    if (sourceId === "zohoanalytics") return;
    fetchTableOFDB();
  }, []);
  const fetchTableOFDB = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_DB_TABLES);
    if (response.status) {
      setSourceData(response.data);
    }
    setLoading(false);
  };

  const sourceNameMap: any = {
    postgre: "Postgre",
    mongodb: "MongoDB",
    zohoanalytics: "Zoho Analytics",
  };

  const getTableDataProcessed = (data: any) => {
    let rowMainData: any = [];
    data.forEach((element: any, key: any) => {
      rowMainData.push({
        sno: key + 1,
        table_name: element,
      });
    });
    return rowMainData;
  };

  const handleSearchWorkspace = (query: string) => {
    if (gridRef.current && gridRef.current.onTableSearch) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  const handleZASchema = () => {
    if (zaId) {
      navigate("/FA/schema/setup", {
        state: { zaId: zaId, sourceType: sourceId },
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Please enter your valid ZA Info",
      });
    }
  };

  return (
    <div className="SchemaSourceDetail">
      <div className="Header">
        <PageHeader
          goBack
          title={`${sourceNameMap?.[sourceId || ""]} Configuration`}
          rightActions={
            <SearchInput
              placeholder="Search Table"
              onSearch={handleSearchWorkspace}
            />
          }
        />
      </div>

      <div className="MainContent">
        {isLoading ? (
          <Loader />
        ) : sourceId !== "zohoanalytics" ? (
          <>
            <div className="DatabaseListContainer">
              <List
                size="small"
                dataSource={
                  Object.keys(sourceData?.[sourceId || ""] || {}) || []
                }
                renderItem={(item) => (
                  <List.Item onClick={() => setSelectedDatabase(item)}>
                    <DatabaseOutlined
                      style={{
                        marginRight: 4,
                        color: colorPicker("neutral.800"),
                      }}
                    />
                    {item}
                  </List.Item>
                )}
              />
            </div>
            <div className="TableViewContainer">
              {selectedDatabase ? (
                <AgTableClient
                  //@ts-ignore
                  rowData={getTableDataProcessed(
                    sourceData?.[sourceId || ""]?.[selectedDatabase] || []
                  )}
                  ref={gridRef}
                  hideToolbar
                  columnDefs={[
                    { field: "sno", headerName: "S.NO" },
                    { field: "table_name", headerName: "Table Name" },
                    {
                      field: "actions",
                      headerName: "Actions",
                      cellRenderer: (params: any) => {
                        return (
                          <div>
                            <Button
                              type="primary"
                              size="small"
                              onClick={() =>
                                navigate("/FA/schema/setup", {
                                  state: {
                                    dbType: sourceId,
                                    database: selectedDatabase,
                                    table: params?.data?.table_name,
                                  },
                                })
                              }
                            >
                              Schema View
                            </Button>
                          </div>
                        );
                      },
                    },
                  ]}
                  autoResize
                />
              ) : (
                <EmptyState />
              )}
            </div>
          </>
        ) : (
          <div className="ThirdPartySourceContainer">
            <Input
              style={{ width: 400 }}
              placeholder="Enter your ZA Table or View ID"
              onChange={(e: any) => setZAId(e.target.value)}
            />
            <Button
              type="primary"
              style={{ marginLeft: 8 }}
              onClick={handleZASchema}
            >
              Generate Schema
            </Button>
          </div>
        )}
      </div>
      {contextHolder}
    </div>
  );
}
