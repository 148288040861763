import PageHeader from "app/shared/PageHeader";
import React, { useState } from "react";
import "./SchemaSource.scss";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { useNavigate } from "react-router-dom";

export default function SchemaSource(props: any) {
  const navigate = useNavigate();
  const handleSelectSource = (source: any) => {
    navigate("/FA/schema/source/detail/" + source);
  };
  return (
    <div className="SchemaSource">
      <div className="Header">
        <PageHeader title="Select Your Source" />
      </div>

      <div className="MainContent">
        <div className="SourceContainer">
          <div
            className="SourceCard"
            onClick={() => handleSelectSource("postgre")}
          >
            <img
              src="https://static-00.iconduck.com/assets.00/postgresql-icon-1987x2048-v2fkmdaw.png"
              alt="SourceCardImage"
            />
            <Typography
              weight={500}
              style={{ color: colorPicker("neutral.800"), marginTop: 6 }}
            >
              Postgre
            </Typography>
            <Typography
              style={{ marginTop: 2, color: colorPicker("neutral.700") }}
              variant="xs"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's.Lorem Ipsum i
            </Typography>
          </div>
          <div
            className="SourceCard"
            onClick={() => handleSelectSource("mongodb")}
          >
            <img
              src="https://cdn.worldvectorlogo.com/logos/mongodb-icon-1.svg"
              alt="SourceCardImage"
            />
            <Typography
              weight={500}
              style={{ color: colorPicker("neutral.800"), marginTop: 6 }}
            >
              MongoDB
            </Typography>
            <Typography
              style={{ marginTop: 2, color: colorPicker("neutral.700") }}
              variant="xs"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's.Lorem Ipsum i
            </Typography>
          </div>
          <div
            className="SourceCard"
            onClick={() => handleSelectSource("zohoanalytics")}
          >
            <img
              src="https://store-images.s-microsoft.com/image/apps.5421.c796a3ff-8636-4f82-ba70-9d1296739af2.852b44b2-20b3-4310-b754-c417a5033e80.7ab375e4-5485-456e-af55-bb3587ca60ec"
              alt="SourceCardImage"
            />
            <Typography
              weight={500}
              style={{ color: colorPicker("neutral.800"), marginTop: 6 }}
            >
              Zoho Analytics
            </Typography>
            <Typography
              style={{ marginTop: 2, color: colorPicker("neutral.700") }}
              variant="xs"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's.Lorem Ipsum i
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
