import PageHeader from "app/shared/PageHeader";
import "./BookingData.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import UnReconciledFlight from "../AppTour/components/OnboardUnReconcilied/UnReconciledFlight";
import UnReconciledHotel from "../AppTour/components/OnboardUnReconcilied/UnReconciledHotel";
import { colorPicker } from "app/utils/color.helper";
import Typography from "app/shared/Typography";
import EmptyState from "app/shared/EmptyState";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOOINT_ONBOARDING_BOOKING_PREVIEW } from "../OnboardingV2/onboardingv2.constants";
import { Statistic } from "antd";
import CountUp from "react-countup";
const formatter = (value: any) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0, // Ensure no decimal places are shown
    maximumFractionDigits: 0,
  }).format(value);
};
export default function BookingData(props: any) {
  const [activeKey, setActiveKey] = useState(1);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [bookingData, setBookingData] = useState<any>(null);
  const [isLoading, setLoading] = useState(true);
  const [tabViews, setTabView] = useState<any>([
    {
      title: "Flight",
      id: 1,
      component: <UnReconciledFlight {...props} />,
    },
    {
      title: "Hotel",
      id: 2,
      component: <UnReconciledHotel {...props} />,
    },
  ]);

  const { Countdown } = Statistic;
  const deadline = new Date().getTime() + 1000 * 60 * 60 * 24 * 73; // 2 days from now

  useEffect(() => {
    fetchBookingGist();
  }, [userDetails.currentWorkspace]);
  const fetchBookingGist = async (segmentType = "FLIGHT") => {
    const response = await apiGet(
      `${API_ENDPOOINT_ONBOARDING_BOOKING_PREVIEW}?booking_type=${segmentType}`
    );
    if (response.status) {
      if (response.data !== null) {
        setBookingData(response.data);
      }
    } else {
    }
    setLoading(false);
  };

  console.log("userDetails", userDetails);
  return (
    <div className="BookingData">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <CustomReconTabs
              activeKey={activeKey}
              onTabChange={(tabKey: any) => setActiveKey(tabKey)}
              // onTabModify={(tabs: any) =>
              //   setTabView({ ...tabViews, dashboard: tabs })
              // }
              hideAddTab
              hideRemove
              defaultTabs={[
                {
                  title: "Flight",
                  id: 1,
                },
                {
                  title: "Hotel",
                  id: 2,
                },
              ]}
            />
          }
        />

        <div className="TableContainer">
          {/* <div
            style={{
              height: "100%",
              width: "100%",
              flex: 1,
            }}
          >
            {activeKey === 1 ? (
              <UnReconciledFlight
                singleWorkspace={userDetails.user_type === "REGULAR"}
                {...props}
              />
            ) : (
              <UnReconciledHotel
                singleWorkspace={userDetails.user_type === "REGULAR"}
                {...props}
              />
            )}
          </div> */}

          <div className="WrapperContainer">
            {userDetails?.currentWorkspace?.name === "MMT Admin" ? null : (
              <div className="RowContainer">
                <div className="Column" style={{ width: "32%" }}>
                  <Typography style={{ color: colorPicker("neutral.800") }}>
                    You have{" "}
                    <b style={{ color: "#eb4d4b" }}>
                      {bookingData?.totalmissingurl || 0}
                    </b>{" "}
                    invoices missing to claim GST amount
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Statistic
                      value={bookingData?.totalgstamount || 0}
                      valueStyle={{
                        color: "#eb4d4b",
                        fontSize: "28px", // Change the font size here
                        fontWeight: 600,
                      }}
                      precision={1}
                      formatter={(value: any) => (
                        <CountUp
                          end={value}
                          duration={2}
                          formattingFn={formatter}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="Column" style={{ width: "10%" }}>
                  <Typography
                    style={{ color: colorPicker("neutral.800") }}
                    variant="caption"
                  >
                    Booking Data
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Statistic
                      value={bookingData?.totalbooking || 0}
                      valueStyle={{
                        color: colorPicker("primary.700"),
                        fontSize: "28px", // Change the font size here
                        fontWeight: 600,
                      }}
                      formatter={(value: any) => (
                        <CountUp end={value as number} separator="," />
                      )}
                    />
                  </div>
                </div>
                <div className="Column" style={{ width: "10%" }}>
                  <Typography
                    style={{ color: colorPicker("neutral.800") }}
                    variant="caption"
                  >
                    Total Invoices
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Statistic
                      value={bookingData?.totalinvoiceurl || 0}
                      valueStyle={{
                        color: colorPicker("primary.700"),
                        fontSize: "28px", // Change the font size here
                        fontWeight: 600,
                      }}
                      formatter={(value: any) => (
                        <CountUp end={value as number} separator="," />
                      )}
                    />
                  </div>
                </div>
                <div className="Column" style={{ width: "12%" }}>
                  <Typography
                    style={{ color: colorPicker("neutral.800") }}
                    variant="caption"
                  >
                    Missing Invoices
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Statistic
                      value={bookingData?.totalmissingurl || 0}
                      valueStyle={{
                        color: colorPicker("primary.700"),
                        fontSize: "28px", // Change the font size here
                        fontWeight: 600,
                      }}
                      formatter={(value: any) => (
                        <CountUp end={value as number} separator="," />
                      )}
                    />
                  </div>
                </div>
                <div className="Column" style={{ width: "31%" }}>
                  <Typography
                    variant="caption"
                    style={{ color: colorPicker("neutral.700") }}
                  >
                    Deadline for claiming GST of 2023-2024 is approaching fast!
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Countdown
                      value={deadline}
                      format="D [days] HH [hours] mm [min] ss [sec]"
                      valueStyle={{
                        color: "#eb4d4b",
                        fontSize: 20,
                        marginTop: 4,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="RowContainer" style={{ flex: 1, marginTop: 14 }}>
              {userDetails?.currentWorkspace?.name !== "MMT Admin" &&
              (!bookingData?.totalbooking ||
                bookingData?.totalbooking === 0) ? (
                <EmptyState
                  title={"No Booking Data"}
                  description={
                    <Typography
                      variant="xs"
                      style={{ color: colorPicker("neutral.700") }}
                    >
                      Please raise a request by clicking here{" "}
                      <b
                        style={{
                          color: colorPicker("primary.700"),
                          cursor: "pointer",
                        }}
                      >
                        Raise Request
                      </b>
                    </Typography>
                  }
                />
              ) : (
                <div
                  className="Column"
                  style={{ width: "100%", height: "100%" }}
                >
                  {/* <OnboardAirlineReconView noFloat /> */}
                  {activeKey === 1 ? (
                    <UnReconciledFlight
                      singleWorkspace={
                        userDetails?.currentWorkspace?.name === "MMT Admin"
                          ? false
                          : true
                      }
                      {...props}
                    />
                  ) : (
                    <UnReconciledHotel
                      singleWorkspace={
                        userDetails?.currentWorkspace?.name == "MMT Admin"
                          ? false
                          : true
                      }
                      {...props}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
