import Typography from "app/shared/Typography";
import "./CustomReconTabs.scss";
import { ExportOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import { Tag } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates } from "app/config/States/users";

const CustomReconTabs = forwardRef((props: any, ref: any) => {
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);

  const [items, setItems] = useState([
    {
      id: 1,
      title: props.title || "Tab",
      type: "VIEW",
      viewData: null,
    },
  ]);

  useEffect(() => {
    if (props.defaultTabs) {
      setItems(props.defaultTabs);
      props.onTabModify && props.onTabModify(props.defaultTabs);
    }
  }, [tabViews.reports]);

  const addTab = (titleArg = "", data = null) => {
    let newId = items[items.length - 1].id;
    setItems([
      ...items,
      {
        id: newId + 1,
        type: titleArg || "VIEW",
        viewData: data,
        title:
          `${titleArg || props.title} ${newId + 1}` ||
          (titleArg || "New Tab ") + newId,
      },
    ]);
    props.onTabModify &&
      props.onTabModify([
        ...items,
        {
          id: newId + 1,
          type: titleArg || "VIEW",
          title: `${props.title} ${newId + 1}` || "New Tab " + newId,
          viewData: data,
        },
      ]);
  };

  const handleRemoveTab = (id: any, e: any) => {
    let findIndexItem = items.findIndex((item) => item.id === id);
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
    props.onTabModify && props.onTabModify(newItems);

    props.onTabChange(1);
    e.stopPropagation();
  };

  React.useImperativeHandle(ref, () => ({
    addTab,
  }));

  return (
    <div className="CustomReconTabs">
      {items.map((item: any, key: any) => {
        return (
          <div
            className={`ReconTab ${
              items.length === 1
                ? " active"
                : props.activeKey === item.id
                ? " active"
                : ""
            }`}
            key={key}
            onClick={() => props.onTabChange(item.id)}
          >
            <Typography
              variant="xs"
              style={{
                color: colorPicker("neutral.800"),
                width: props.width || "auto",
              }}
              wrapped
            >
              {item.title}{" "}
            </Typography>{" "}
            {!props.hideRemove && key > 0 ? (
              <CloseOutlined
                onClick={(e) => handleRemoveTab(item.id, e)}
                style={{
                  color: colorPicker("neutral.800"),
                  marginLeft: 6,
                  cursor: "pointer",
                  fontSize: 12,
                  marginTop: 2,
                }}
              />
            ) : null}
          </div>
        );
      })}
      {!props.hideAddTab ? (
        <div style={{ padding: 0 }}>
          <PlusOutlined
            style={{
              color: colorPicker("neutral.900"),
              cursor: "pointer",
              fontSize: 12,
            }}
            onClick={() => addTab("")}
          />
        </div>
      ) : null}
    </div>
  );
});

export default CustomReconTabs;
