import { Button, Input, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import "./TableViewManager.scss";
import { colorPicker } from "app/utils/color.helper";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { apiGet } from "app/services/apiServices";
import {
  API_ENDPOINT_AG_TABLE_LIST_ALL_VIEW,
  API_ENDPOINT_AG_TABLE_LOAD_VIEW,
} from "../AgTable/AgTable.constants";
import Typography from "../Typography";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
export default function TableViewManager(props: any) {
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [userDetails, _] = useRecoilState<any>(userInfo);

  const [views, setViews] = useState<any[]>([]);
  const [viewName, setViewName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedView, setSelectedView] = useState("DEFAULT");
  const [messageApi, contextHolder] = message.useMessage();

  const getMenuItems = () => {};

  const fetchTableViews = async () => {
    const response = await apiGet(
      API_ENDPOINT_AG_TABLE_LIST_ALL_VIEW + props.moduleId
    );
    createSelectOptions(response.data);
  };

  const createSelectOptions = (rowData: any) => {
    let options: any = [
      {
        label: "Default View",
        value: "DEFAULT",
      },
    ];
    rowData.forEach((optionData: any) => {
      options.push({
        label: optionData.name,
        value: optionData.viewId,
      });
    });
    setViews(options);
  };
  useEffect(() => {
    fetchTableViews();
  }, [props.moduleId, userDetails.currentWorkspace]);

  const handleAddNewView = () => {
    // setShowModal(true);
    setCreateMode(true);
    messageApi.open({
      type: "info",
      content: "Please make your changes and save",
      duration: 5,
    });
  };

  const handleSaveNewView = () => {
    setShowModal(true);
  };
  const handleSaveView = async () => {
    props.createView && props.createView(viewName);
    setShowModal(false);
    setViewName("");
    setTimeout(() => {
      fetchTableViews();
    }, 2000);
    setCreateMode(false);
  };
  return (
    <div className="TableViewManager">
      <Select
        placeholder="Default View"
        variant="filled"
        size="small"
        style={{ width: 180 }}
        options={views}
        value={selectedView}
        onChange={(viewInfo: any) => {
          props.selectView && props.selectView(viewInfo);
          setSelectedView(viewInfo);
        }}
      />
      {editMode ? (
        <>
          <div
            className="eidtCircleButton"
            style={{ background: colorPicker("green.500") }}
            onClick={() => {
              props.updateView && props.updateView(selectedView);
              setEditMode(false);
            }}
          >
            <CheckIcon style={{ fontSize: 14, color: "white" }} />
          </div>

          <div
            className="eidtCircleButton"
            style={{ background: colorPicker("red.700") }}
          >
            <CloseIcon
              style={{ fontSize: 14, color: "white" }}
              onClick={() => setEditMode(false)}
            />
          </div>
        </>
      ) : (
        <>
          {selectedView !== "DEFAULT" ? (
            <div
              className="eidtCircleButton"
              style={{ background: colorPicker("green.500") }}
            >
              <EditIcon
                style={{ fontSize: 15, color: "white" }}
                onClick={() => {
                  setEditMode(true);
                  messageApi.open({
                    type: "info",
                    content: "Please make your changes and save",
                    duration: 5,
                  });
                }}
              />
            </div>
          ) : null}

          {createMode ? (
            <>
              <div
                className="eidtCircleButton"
                style={{ background: colorPicker("green.500") }}
                onClick={handleSaveNewView}
              >
                <CheckIcon style={{ fontSize: 14, color: "white" }} />
              </div>

              <div
                className="eidtCircleButton"
                style={{ background: colorPicker("red.700") }}
              >
                <CloseIcon
                  style={{ fontSize: 14, color: "white" }}
                  onClick={() => setCreateMode(false)}
                />
              </div>
            </>
          ) : (
            <div
              className="eidtCircleButton"
              style={{ background: colorPicker("primary.700") }}
            >
              <AddIcon
                style={{ fontSize: 15, color: "white" }}
                onClick={handleAddNewView}
              />
            </div>
          )}
        </>
      )}
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        closable={false}
      >
        <Typography
          // variant="caption"
          style={{ color: colorPicker("neutral.800"), marginBottom: 12 }}
        >
          Create View
        </Typography>
        <Input
          placeholder="View Name"
          onChange={(e: any) => setViewName(e.target.value)}
          value={viewName}
        />
        <div
          style={{
            marginTop: 12,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{ marginRight: 12 }}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button onClick={handleSaveView} type="primary">
            Save
          </Button>
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}
