import Uploader from "app/shared/Uploader";
import "./SingleInvoiceUpload.scss";
import Typography from "app/shared/Typography";
import { apiPost, apiPostForm } from "app/services/apiServices";
import { API_ENDPOINT_STORAGE_S3_UPLOAD } from "app/scenes/Invoices/invoices.constants";
import { useEffect, useState } from "react";
import { Modal, message } from "antd";
import { colorPicker } from "app/utils/color.helper";
import {
  API_ENDPOINT_GET_REF_TOKEN_INFO,
  API_ENDPOINT_HOTEL_BOOKING_UPDATE_INVOICE_INFO,
  API_ENDPOINT_STORAGE_S3_UPLOAD_OPEN,
} from "../../hotel.booking.constants";
import Loader from "app/shared/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import poweredFinkraft from "static/images/poweredFinkraft.png";
import { appMetaConfig } from "global.constants";
export default function SingleInvoiceUpload(props: any) {
  const [uploaded, setUploaded] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  useEffect(() => {
    let refToken = queryParams.get("ref");
    fetchRefTokenInfo(refToken || "");
  }, []);

  const fetchRefTokenInfo = async (token: string) => {
    const response = await apiPost(API_ENDPOINT_GET_REF_TOKEN_INFO, {
      token: token,
    });
    if (response.status) {
      setTokenInfo(response.data);
    } else {
      navigate(-1);
    }
  };

  function getFileExtension(fileName: string) {
    // Split the file name by the dot character
    let parts = fileName.split(".");

    // Get the last part of the array which should be the file extension
    let extension = parts[parts.length - 1];

    return extension;
  }

  const handleFileUpload = async (file: any) => {
    setLoading(true);
    let formData = new FormData();
    let fileExtension = getFileExtension(file.name);
    formData.append("file", file);
    formData.append(
      "filename",
      `${tokenInfo.confirmationNumber}_${
        tokenInfo.recordLocator
      }_${moment().unix()}.${fileExtension}`
    );
    formData.append("path", "");

    formData.append("bucket_name", "hotel-booking-invoices");

    const response = await apiPostForm(
      API_ENDPOINT_STORAGE_S3_UPLOAD_OPEN,
      formData
    );
    if (response.status) {
      updateInvoiceInBooking(response.data.Location);
    } else {
      messageApi.error({
        type: "error",
        content: "Could not upload invoice for the booking",
      });
    }
  };

  const updateInvoiceInBooking = async (invoiceLink: string) => {
    const response = await apiPost(
      API_ENDPOINT_HOTEL_BOOKING_UPDATE_INVOICE_INFO,
      {
        recordLocator: tokenInfo.recordLocator,
        invoice_link: invoiceLink,
      }
    );
    if (response.status) {
      setUploaded(true);
      setLoading(false);
    } else {
    }
    setLoading(false);
  };
  return (
    <div className="SingleInvoiceUpload">
      {/* <div className="TopInformationContainer">
        <Typography>
          Confirmation Number - <b>{tokenInfo?.confirmationNumber}</b>{" "}
        </Typography>
        <Typography>
          Booking Date -{" "}
          <b>{moment(tokenInfo?.bookingTime).format("DD/MM/YYYY, HH:MM A")}</b>{" "}
        </Typography>

        <Typography>
          Checkin Date -{" "}
          <b>{moment(tokenInfo?.checkinTime).format("DD/MM/YYYY, HH:MM A")}</b>{" "}
        </Typography>
        <Typography>
          Checkout Date -{" "}
          <b>{moment(tokenInfo?.checkoutTime).format("DD/MM/YYYY, HH:MM A")}</b>{" "}
        </Typography>
      </div> */}
      <div className="LogoContainer">
        <img src="https://www.bcdtravel.com/wp-content/uploads/BCD-Travel-Logo-as-SVG-file-min.svg" />
        <img src="https://www.novartis.com/themes/custom/polaris/logo.svg" />
      </div>
      <div className="UploadContainer">
        <Typography variant="h6" style={{ marginBottom: 12 }}>
          Upload Invoice
        </Typography>

        <Typography
          variant="caption"
          style={{ color: colorPicker("neutral.700"), marginBottom: 24 }}
        >
          Please upload invoice for booking made with reference to booking
          confirmation/ TRF ID -{" "}
          <b style={{ color: colorPicker("primary.700") }}>
            {tokenInfo?.confirmationNumber || "FSDFSDFSDF"}
          </b>{" "}
        </Typography>
        <div style={{ flex: 1 }}>
          {isLoading ? (
            <Loader />
          ) : (
            <Uploader
              fileType={
                <Typography
                  variant="xs"
                  style={{
                    textAlign: "center",
                    color: colorPicker("neutral.700"),
                  }}
                >
                  PDF/Image Only,{" "}
                  <b>
                    * please select multiple if you have more than 1 invoice
                  </b>
                </Typography>
              }
              onSelect={handleFileUpload}
            />
          )}
        </div>
      </div>

      <img
        src={appMetaConfig?.app?.logo}
        style={{ marginTop: 24, width: 120 }}
      />
      <Modal
        open={uploaded}
        footer={false}
        // onCancel={() => setUploaded(false)}
        closable={false}
      >
        <div
          style={{
            height: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">🎉 Thanks for uploading invoice</Typography>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.800") }}
          >
            you can close this window now
          </Typography>
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}
