import PageHeader from "app/shared/PageHeader";
import OnboardAirlineReconView from "../AppTour/components/OnboardAirlineReconView/s";
import Reconcilation from "./Reconcilation";
import AirlineReconcilation from "./components/AirlineReconcilation";
import GSTReconcilation from "./components/GSTReconcilation";
import OnboardHotelReconView from "../AppTour/components/OnboardHotelReconView";
import FlighAirlineReconZA from "./components/AirlineReconcilationZA";

//eslint-disable-next-line
export default {
  path: "/reconcilation/",
  element: <Reconcilation />,
  strict: true,
  children: [
    {
      path: "/reconcilation/gst",
      // element: <GSTReconcilation />,
      element: (
        <div style={{ height: "100%" }}>
          <PageHeader title="Hotel Recon (Demo)" />
          <OnboardHotelReconView noFloat />
        </div>
      ),
      exact: true,
    },
    {
      path: "/reconcilation/airline",
      // element: <AirlineReconcilation />,
      element: (
        <div style={{ height: "100vh" }}>
          <PageHeader title="Air Recon" />
          <FlighAirlineReconZA noFloat />
        </div>
      ),
      exact: true,
    },
  ],
};
