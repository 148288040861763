import Uploader from "app/shared/Uploader";
import "./SingleInvoiceUpload.scss";
import Typography from "app/shared/Typography";
import { apiPost, apiPostForm } from "app/services/apiServices";
import { API_ENDPOINT_STORAGE_S3_UPLOAD } from "app/scenes/Invoices/invoices.constants";
import { useEffect, useState } from "react";
import { Modal, message } from "antd";
import { colorPicker } from "app/utils/color.helper";
import { API_ENDPOINT_ACKNOWLEDGMENT_APPROVAL_SYNC } from "../../../HotelBookingView/hotel.booking.constants";
import Loader from "app/shared/Loader";
import Lottie from "react-lottie";
import DoneLottie from "static/lottie/doneLottie.json";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import poweredFinkraft from "static/images/poweredFinkraft.png";
import { CheckCircleFilled } from "@ant-design/icons";
export default function AcknowledgeApproval(props: any) {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const navigate = useNavigate();
  let { token } = useParams();

  useEffect(() => {
    AcknowledgmentApprovalSync();
  }, []);
  const AcknowledgmentApprovalSync = async () => {
    setLoading(true);
    const response = await apiPost(
      API_ENDPOINT_ACKNOWLEDGMENT_APPROVAL_SYNC + token,
      {
        token: token,
      }
    );
    if (response.status) {
      setApproved(response.data);
    } else {
      navigate(-1);
    }
    setLoading(false);
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: DoneLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="SingleInvoiceUpload">
      <div className="LogoContainer">
        <img src={"https://static-assets.finkraftai.com/logo/mmt.png"} />
      </div>
      <div className="UploadContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Lottie options={lottieOptions} height={88} width={88} />

            <Typography
              variant="h6"
              style={{ color: colorPicker("neutral.800") }}
            >
              Awesome!, We have sent you the credentials
            </Typography>
          </>
        )}
      </div>

      <img src={poweredFinkraft} style={{ marginTop: 24 }} />

      {contextHolder}
    </div>
  );
}
