import { Auth } from ".";
import AcknowledgeApproval from "./components/ApprovalAcknowledge";
import AutoLogin from "./components/AutoLogin";
import EmailSent from "./components/EmailSent";
import InviteJoin from "./components/InviteJoin";
import Login from "./components/Login";
import LoginOTP from "./components/LoginOTP";
import OAuthCallback from "./components/OAuthCallback";
import Signup from "./components/Signup";
import WorkspaceInvite from "./components/WorkspaceInvite";

//eslint-disable-next-line
export default {
  path: "/auth/",
  element: <Auth />,
  strict: true,
  children: [
    {
      path: "/auth/signin",
      element: <Login />,
      exact: true,
    },
    {
      path: "/auth/signin_otp",
      element: <LoginOTP />,
      exact: true,
    },
    {
      path: "/auth/verify/:token",
      element: <OAuthCallback />,
      exact: true,
    },
    {
      path: "/auth/email/sent",
      element: <EmailSent />,
      exact: true,
    },
    {
      path: "/auth/autologin/:token",
      element: <AutoLogin />,
      exact: true,
    },

    {
      path: "/auth/signup",
      element: <Signup />,
      exact: true,
    },
    {
      path: "/auth/invitation/:inviteToken",
      element: <WorkspaceInvite />,
      exact: true,
    },
    {
      path: "/auth/invitation/register",
      element: <InviteJoin />,
      exact: true,
    },
  ],
};
