//** [UploadComponent.js] **//
import { apiPost } from "app/services/apiServices";
import React, { useState } from "react";

const Multipart = () => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
  };

  const arrayBufferToBase64 = (buffer) => {
    // ArrayBuffer to Base64 conversion
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const uploadPartSize = 10 * 1024 * 1024; // Use larger part size like 10MB
    let start = 0;
    const fileKey = `multipart/${file.name}`;
    const numParts = Math.ceil(file.size / uploadPartSize);
    let uploadId;

    try {
      const createMultipartUploadRes = await apiPost(
        "http://localhost:7001/api/storage/objects/create-multipart-upload",
        {
          fileName: file.name,
          fileType: file.type,
        }
      );

      uploadId = createMultipartUploadRes.data.UploadId;

      const uploadedParts = [];
      while (start < file.size) {
        const batchSize = Math.min(5, numParts - uploadedParts.length); // Upload 5 parts concurrently
        const promises = [];

        for (let i = 0; i < batchSize && start < file.size; i++) {
          const end = Math.min(start + uploadPartSize, file.size);
          const chunk = file.slice(start, end);

          const arrayBuffer = await chunk.arrayBuffer();
          const chunkBase64 = arrayBufferToBase64(arrayBuffer);
          const partNumber = uploadedParts.length + 1;

          const uploadPartPromise = await apiPost(
            "http://localhost:7001/api/storage/objects/upload/part",
            {
              Bucket: "colmapping",
              Key: fileKey,
              PartNumber: partNumber,
              UploadId: uploadId,
              PartBuffer: chunkBase64,
            }
          );
          uploadedParts.push({
            ETag: uploadPartPromise.data.ETag,
            PartNumber: partNumber,
          });
          setUploadProgress((start / file.size) * 100); // Update Progress

          promises.push(uploadPartPromise);
          start = end;
        }

        await Promise.all(promises);
      }

      const completeMultipartUploadRes = await apiPost(
        "http://localhost:7001/api/storage/objects/upload/part/complete",
        {
          Bucket: "colmapping",
          Key: fileKey,
          UploadId: uploadId,
          Parts: uploadedParts,
        }
      );

      alert("Upload successful!");
      console.log("Final uploaded data:", completeMultipartUploadRes.data);
    } catch (err) {
      console.error("Error during multipart upload:", err);
    }
  };

  return (
    <div>
      <h2>Multipart File Upload to S3</h2>
      <input type="file" onChange={handleFileInput} />
      <button onClick={handleUpload}>Upload</button>
      <div>
        <p>Upload Progress: {uploadProgress.toFixed(2)}%</p>
      </div>
    </div>
  );
};

export default Multipart;
