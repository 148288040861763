export const processZARowForColumnDefs = (columnList: any) => {
  let rowDataArray: any = [];

  let KeyValueData = Object.entries(columnList[0]);

  KeyValueData.forEach((item) => {
    rowDataArray.push({
      id: item[0],
      source_key: item[0],
      destination_key: item[0].replace(/[\s_-]/g, ""),
      data_type: (typeof item[1]).toUpperCase(),
      header_name: item[0],
      ui_format_type: "FALSE",
      enable_aggregation:
        (typeof item[1]).toUpperCase() === "NUMBER" ? true : false,
      enable_row_group: true,
      enable_column_pivot: false,
      filter_type: (typeof item[1]).toUpperCase(),
      hide_column: false,
      default_grouping: false,
      default_pinning: "FALSE",
      column_group_show: false,
      default_sorting: "FALSE",
      column_grouping: "",
      match_config_json: "{}",
    });
  });
  return rowDataArray;
};

export const processTableRowForColumnDef = (columnList: any) => {
  let rowDataArray: any = [];

  let KeyValueData = Object.entries(columnList[0]);

  KeyValueData.forEach((item) => {
    rowDataArray.push({
      id: item[0],
      source_key: item[0],
      destination_key: item[0].replace(/[\s_-]/g, ""),
      data_type: (typeof item[1]).toUpperCase(),
      header_name: item[0],
      ui_format_type: "FALSE",
      enable_aggregation:
        (typeof item[1]).toUpperCase() === "NUMBER" ? true : false,
      enable_row_group: true,
      enable_column_pivot: false,
      filter_type: (typeof item[1]).toUpperCase(),
      hide_column: false,
      default_grouping: false,
      default_pinning: "FALSE",
      column_group_show: false,
      default_sorting: "FALSE",
      column_grouping: "",
      match_config_json: "{}",
    });
  });
  return rowDataArray;
};
