import React from "react";
import "./FlightDashboard.scss";
import PageHeader from "app/shared/PageHeader";
import { AgTableClient } from "app/shared/AgTable";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import EmptyState from "app/shared/EmptyState";
export default function FlightDashboard(props: any) {
  const [userDetails, _] = useRecoilState<any>(userInfo);

  return (
    <div className="FlightDashboard" style={{ height: "100%" }}>
      {userDetails?.currentWorkspace?.name === "MMT Admin" ||
      userDetails?.currentWorkspace?.meta?.zoho_workspace_name ? (
        <iframe
          height="100%"
          style={{ width: "100%" }}
          scrolling="no"
          title="Iframe"
          src={`https://analytics.zoho.in/open-view/103074000027919816/2ba77f9f05c7d79a4354eaed633ad158${
            userDetails?.currentWorkspace?.name === "MMT Admin"
              ? ``
              : `?ZOHO_CRITERIA="workspace"%3D%27${userDetails?.currentWorkspace?.meta?.zoho_workspace_name}%27`
          }`}
        ></iframe>
      ) : (
        <EmptyState />
      )}
    </div>
  );
}
