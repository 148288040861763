import { colorPicker } from "app/utils/color.helper";
import Typography from "../Typography";
import "./CRMTransfers.scss";
import { DatabaseOutlined } from "@ant-design/icons";
import { AgTableClient } from "../AgTable";
import { Button, message, Select } from "antd";
import { Widgets } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_CRM_SAVE_MAPPING_CONFIG,
  API_ENDPOINT_FETCH_CRM_MODULE_FIELDS,
  API_ENDPOINT_FETCH_CRM_MODULES,
} from "app/scenes/FinkAnalytics/finkanalytics.constants";

export default function CRMTransfers(props: any) {
  const [selectedFrequency, setSelectedFrequency] = useState("DAILY");
  const [selectedModule, setSelectedModule] = useState("");
  const [fields, setFileds] = useState<any[]>([]);
  const [moduleList, setModuleList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const gridRef: any = useRef();

  useEffect(() => {
    fetchCRMModules();
  }, [props.crmInfo]);

  console.log("crm props:", props);
  const fetchCRMModules = async () => {
    let payload = {
      create_new_workspace: false,
      workspace_id: "3168855b-ecf4-4514-8d4d-77842940e4d8",
    };
    const response = await apiGet(API_ENDPOINT_FETCH_CRM_MODULES);
    if (response.status) {
      setModuleList(response.data);
      if (props.crmInfo) {
        setSelectedFrequency(props.crmInfo?.frequency);
        setSelectedModule(props?.crmInfo?.module_name);
        setFileds(props?.crmInfo?.mapping);
      }
    }
  };

  const getModuleItemList = (modules: any) => {
    let moduleArray: any = [];
    modules.forEach((element: any) => {
      moduleArray.push({
        value: element.api_name,
        label: element.plural_label,
      });
    });
    return moduleArray;
  };
  const handleSelectModule = async (module: any) => {
    setSelectedModule(module);
    const response = await apiGet(
      API_ENDPOINT_FETCH_CRM_MODULE_FIELDS + module
    );
    if (response.status) {
      setFileds(response.data);
    }
  };

  const getColumnMappingList = () => {
    let moduleArray: any = [];
    (props.columnDefs || []).forEach((element: any) => {
      moduleArray.push({
        value: element.field,
        label: element.headerName,
      });
    });
    return moduleArray;
  };

  const handleAddColumnMapping = (
    columnData: any,
    changeInfo: any,
    columnId: any
  ) => {
    // Create a copy of the row to be updated
    if (columnData[columnId] === changeInfo) {
      return; // If no change, exit early
    }
    const updatedRow = {
      ...columnData,
      [columnId]: changeInfo,
    };

    // Apply the update transaction
    gridRef.current &&
      gridRef.current.applyTransaction({ update: [updatedRow] });
  };

  // const handleAddColumnMapping = (dataObject: any, value: any) => {
  //   // Update the state by mapping through the array and adding/updating the key
  //   const updatedData = fields.map((item: any) => {
  //     // Check if the current object's field_label matches the one we're looking for
  //     if (item.field_label === dataObject.field_label) {
  //       // Add or update the table_column key
  //       return { ...item, table_column: value };
  //     }
  //     return item; // return the original item if no match
  //   });

  //   // Update the state with the new array
  //   setFileds(updatedData);
  // };

  const handleSaveConfiguration = async () => {
    console.log("view props:", props);
    const payload = {
      module: selectedModule,
      viewId: props.view_id,
      frequency: selectedFrequency,
      mapping: gridRef.current && gridRef.current.getFinalDataFromTable(),
    };
    console.log("final payload:", payload);

    const response = await apiPost(
      API_ENDPOINT_CRM_SAVE_MAPPING_CONFIG,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
    } else {
      messageApi.success({
        type: "success",
        content: "Could not sync configuration",
      });
    }
  };

  const getDefaultValueOfColumnMapping = (mapId: string) => {
    let findItem = (props.mapping || []).find(
      (item: any) => item.field_label === mapId
    );
    if (findItem) {
      return findItem.table_column;
    } else {
      return "";
    }
  };

  return (
    <div className="CRMTransfers">
      <div className="MainContent">
        <div className="ItemRow">
          <Typography
            style={{ marginRight: 24, color: colorPicker("neutral.900") }}
            variant="caption"
          >
            Select CRM Module
          </Typography>
          <Select
            placeholder="Select your module"
            style={{ width: 200 }}
            options={getModuleItemList(moduleList)}
            value={selectedModule}
            onChange={handleSelectModule}
          />
        </div>

        <div className="ItemRow">
          <Typography
            style={{ marginRight: 24, color: colorPicker("neutral.900") }}
            variant="caption"
          >
            Frequency of Sync
          </Typography>
          <Select
            placeholder="Select your column"
            style={{ width: 200 }}
            onChange={(sync: any) => setSelectedFrequency(sync)}
            value={selectedFrequency}
            options={[
              {
                label: "Hourly",
                value: "HOURLY",
              },
              {
                label: "Daily",
                value: "DAILY",
              },
              {
                label: "Weekly",
                value: "WEEKLY",
              },
            ]}
          />
        </div>
        <div className="ItemRow table" style={{ height: 400 }}>
          <AgTableClient
            // @ts-ignore
            rowData={fields}
            ref={gridRef}
            columnDefs={[
              { field: "field_label", headerName: "CRM Field", width: 200 },
              {
                field: "table_column",
                headerName: "Column Mapping",
                width: 200,
                cellRenderer: (params: any) => (
                  <Select
                    style={{ width: 200 }}
                    options={getColumnMappingList()}
                    allowClear
                    size="small"
                    value={params?.data?.table_column}
                    onChange={(selectedValue: any) =>
                      handleAddColumnMapping(
                        params.data,
                        selectedValue,
                        "table_column"
                      )
                    }
                  />
                ),
              },
            ]}
            hideToolbar
            autoResize
            rowIdKey={"field_label"}
            useRowId
          />
        </div>
        <div className="ItemRow table" style={{ justifyContent: "flex-end" }}>
          <Button type="primary" onClick={handleSaveConfiguration}>
            Save Configuration
          </Button>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
