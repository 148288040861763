import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import { useEffect, useMemo, useState } from "react";

import Loader from "app/shared/Loader";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";

import "./MMT.scss";

import jsonData from "./data.json";

import { Button, FloatButton, Spin, Tour, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
import Typography from "app/shared/Typography";
export default function OnboardAirlineReconView(props: any) {
  const [tableData, setTableData] = useState(jsonData);
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [summarizedData, setSummariedData] = useState<any[]>([]);

  const openSidebarRenderer = (params: any) => {
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };

  const columnDefs = [
    {
      field: "S3 Link",
      headerName: "Invoice",
      enableRowGroup: true,
      invoiceLink: "html.pdf",
      cellRenderer: ToolParamInvoker,
      cellClass: "hyperlinks", // references excel style ,

      cellRendererParams: () => {
        return {
          label: "View Invoice",
          handleClick: openSidebarRenderer,
        };
      },
      minWidth: 200,
    },
    {
      field: "bookingId",
      headerName: "Booking ID",
      minWidth: 200,
      enableRowGroup: true,
    },
    {
      field: "Vendor Name",
      headerName: "Vendor Name",
      minWidth: 200,
      enableRowGroup: true,
    },
    {
      field: "Claimable Status",
      headerName: "Claimable Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "MATCH",
      enablePivot: true,
      formatProps: {
        matchLogic: {
          matchColor: {
            Claimable: "green",
            Unclaimable: "red",
          },
          icon: {
            Claimable: <CheckCircleFilled />,
            Unclaimable: <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },
    {
      field: "Invoice/Note_Number Match Status",
      headerName: "Invoice/Note_Number Match Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "MATCH",
      formatProps: {
        matchLogic: {
          matchColor: {
            Match: "green",
            Mismatch: "red",
          },
          icon: {
            Match: <CheckCircleFilled />,
            Mismatch: <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },

    {
      field: "Supplier_GSTIN Match Status",
      headerName: "Supplier_GSTIN Match Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "MATCH",
      formatProps: {
        matchLogic: {
          matchColor: {
            Match: "green",
            Mismatch: "red",
          },
          icon: {
            Match: <CheckCircleFilled />,
            Mismatch: <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },
    {
      headerName: "2A/2B",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      children: [
        {
          field: "2A/2B-Invoice/Note_Number",
          headerName: "2A/2B-Invoice/Note_Number",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE_TEXT",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["Invoice-Invoice/Note_Number"],
          },
        },
        {
          field: "2A/2B-Supplier_GSTIN",
          headerName: "2A/2B-Supplier_GSTIN",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE_TEXT",
          formatProps: {
            compareObjects: ["Invoice-Supplier_GSTIN"],
          },
          ...handleCellRenderer(),
        },
        {
          field: "2A/2B-Customer_GSTIN",
          headerName: "2A/2B-Customer_GSTIN",
          enableRowGroup: true,
          minWidth: 200,
          ...handleCellRenderer(),
          formatType: "404",
        },
        {
          field: "2A/2B-CGST",
          headerName: "2A/2B-CGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "2A/2B-SGST",
          headerName: "2A/2B-SGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "2A/2B-IGST",
          headerName: "2A/2B-IGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },

        {
          field: "Note Type",
          headerName: "Note Type",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
          ...handleCellRenderer(),
          formatType: "404",
        },
        {
          field: "Type",
          headerName: "Type",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
          ...handleCellRenderer(),
          formatType: "404",
        },
        {
          field: "Financial_Year",
          headerName: "Financial_Year",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
          ...handleCellRenderer(),
          formatType: "404",
        },
        {
          field: "2A/2B-Invoice/Note_Date",
          headerName: "2A/2B-Invoice/Note_Date",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "DATE",
          ...handleCellRenderer(),
          cellClass: "dateType",
        },

        {
          field: "2A/2B-Tax_Rate",
          headerName: "2A/2B-Tax_Rate",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
          cellRenderer: (params: any) => {
            return (
              <div
                style={{
                  color:
                    parseInt(params.data["2A/2B-Tax_Rate"]) >= 18
                      ? "#dd5061"
                      : "",
                  fontWeight:
                    parseInt(params.data["2A/2B-Tax_Rate"]) >= 18 ? 700 : 400,
                }}
              >
                {params.data["2A/2B-Tax_Rate"]}
              </div>
            );
          },
        },
        {
          field: "2A/2B-Taxable",
          headerName: "2A/2B-Taxable",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
          enableValue: true,
        },
        {
          field: "2A/2B-Total_GST",
          headerName: "2A/2B-Total_GST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
          enableValue: true,
        },
        {
          field: "2A/2B-Total_Amount",
          headerName: "2A/2B-Total_Amount",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          enableValue: true,
          cellClass: "numberFormat",
        },
      ],
    },

    {
      headerName: "Invoice",
      children: [
        {
          field: "Invoice-Invoice/Note_Number",
          headerName: "Invoice-Invoice/Note_Number",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE_TEXT",
          formatProps: {
            compareObjects: ["2A/2B-Invoice/Note_Number"],
          },
          ...handleCellRenderer(),
        },
        {
          field: "Invoice-Supplier_GSTIN",
          headerName: "Invoice-Supplier_GSTIN",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE_TEXT",
          formatProps: {
            compareObjects: ["2A/2B-Supplier_GSTIN"],
          },
          ...handleCellRenderer(),
        },

        {
          field: "Invoice-Invoice/Note_Date",
          headerName: "Invoice-Invoice/Note_Date",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
          ...handleCellRenderer(),
        },

        {
          field: "Invoice-Customer_GSTIN",
          headerName: "Invoice-Customer_GSTIN",
          enableRowGroup: true,
          formatType: "404",
          minWidth: 200,
          ...handleCellRenderer(),
        },
        {
          field: "Invoice-Tax_Rate",
          headerName: "Invoice-Tax_Rate",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
          enableValue: true,
          filter: "agNumberColumnFilter",
          cellRenderer: (params: any) => {
            return (
              <div
                style={{
                  color:
                    parseInt(params.data["Invoice-Tax_Rate"]) >= 18
                      ? "#dd5061"
                      : "",
                  fontWeight:
                    parseInt(params.data["Invoice-Tax_Rate"]) >= 18 ? 700 : 400,
                }}
              >
                {params.data["Invoice-Tax_Rate"]}
              </div>
            );
          },
        },
        {
          field: "Invoice-Taxable",
          headerName: "Invoice-Taxable",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
          enableValue: true,
          filter: "agNumberColumnFilter",
        },
        {
          field: "Invoice-CGST",
          headerName: "Invoice-CGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
          aggFunc: "sum",
        },
        {
          field: "Invoice-SGST",
          headerName: "Invoice-SGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
          enableValue: true,
          filter: "agNumberColumnFilter",
        },

        {
          field: "Invoice-IGST",
          headerName: "Invoice-IGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
          enableValue: true,
          filter: "agNumberColumnFilter",
        },
        {
          field: "Invoice-Total_GST",
          headerName: "Invoice-Total_GST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
          enableValue: true,
          filter: "agNumberColumnFilter",
        },
        {
          field: "Invoice-Total_Amount",
          headerName: "Invoice-Total_Amount",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          enableValue: true,
          filter: "agNumberColumnFilter",
        },
      ],
    },

    {
      field: "GST Difference",
      headerName: "GST Difference",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "AMOUNT",
    },

    {
      field: "Ticket/PNR",
      headerName: "Ticket/PNR",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return params.data?.["Ticket/PNR"] || "-";
      },
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      enableRowGroup: true,
      minWidth: 300,
    },
  ];

  const calculateSummary = (data: any) => {
    // Example summary calculations (total number of workspaces):
    let totalInvoiceAmount = 0;
    let totalGSTAmount = 0;
    data.forEach((element: any) => {
      totalInvoiceAmount += element["2A/2B-Total_Amount"];
    });
    // Return summary object. Keys should match data fields for columns.
    return [
      {
        "Vendor Name": "All Vendors",
        "Claimable Status": "",
        "2A/2B-CGST": 0,
        "2A/2B-SGST": 0,
        "2A/2B-IGST": 7503.05,
        "2A/2B-Total_GST": 7503,
        "Invoice/Note_Number Match Status": "",
        "2A/2B-Invoice/Note_Number": "",
        "Invoice-Invoice/Note_Number": "",
        "Supplier_GSTIN Match Status": "",
        "2A/2B-Supplier_GSTIN": "",
        "Invoice-Supplier_GSTIN": "",
        "GST Difference": 0,
        "2A/2B-Total_GST__1": 7503,
        "Invoice-Total_GST": 7504,
        Transaction_Type: "",
        "Note Type": "",
        Type: "",
        Financial_Year: "",
        "2A/2B-Invoice/Note_Number__1": "",
        "2A/2B-Invoice/Note_Date": "",
        "2A/2B-Supplier_GSTIN__1": "",
        "2A/2B-Customer_GSTIN": "",
        "2A/2B-Tax_Rate": 0,
        "2A/2B-Taxable": 0,
        "2A/2B-CGST__1": 0,
        "2A/2B-SGST__1": 0,
        "2A/2B-IGST__1": 0,
        "2A/2B-Total_GST__2": 0,
        "2A/2B-Total_Amount": totalInvoiceAmount,
        "Invoice-Invoice/Note_Number__1": "",
        "Invoice-Invoice/Note_Date": "",
        "Invoice-Supplier_GSTIN__1": "",
        "Invoice-Customer_GSTIN": "",
        "Invoice-Tax_Rate": 0,
        "Invoice-Taxable": 0,
        "Invoice-CGST": 0,
        "Invoice-SGST": 0,
        "Invoice-IGST": 7504,
        "Invoice-Total_GST__1": 0,
        "Invoice-Total_Amount": 0,
        "Invoice Link": "",
        "S3 Link": "",
        "Ticket/PNR": 0,
        Remarks: "",
      },
    ];
  };

  useEffect(() => {
    setSummariedData(calculateSummary(tableData));
  }, []);
  // const steps: any = [
  //   {
  //     selector: ".g-root-wrapper",
  //     content: "This is my first Step",
  //   },
  // ];

  return (
    <div className="TableContainer" style={{ height: "100vh" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <AgTableClient
          // @ts-ignore
          rowData={tableData}
          columnDefs={columnDefs}
          groupPanel
          enablePDFViewer
          pdfDataKey="S3 Link"
          onColGroup={props.onColGroup && props.onColGroup}
          defaultPivot={[""]}
          // autoGroupColumnDef={["Vendor Name"]}
          pinnedBottomRowData={summarizedData}
          showExport
        />
      )}
      {!props.noFloat ? (
        <FloatButton
          onClick={props.goNext}
          style={{ marginRight: 24, width: 200 }}
          shape="square"
          type="primary"
          icon={
            <div style={{ display: "flex", width: "100%" }}>
              <Typography style={{ color: "white", marginRight: 6 }}>
                Next
              </Typography>
              <RightCircleOutlined />
            </div>
          }
        />
      ) : null}

      {contextHolder}
    </div>
  );
}
