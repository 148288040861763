import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./HotelRecon.scss";
import PageHeader from "app/shared/PageHeader";
import { AgTableClient, AgTableServer } from "app/shared/AgTable";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";

import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";

import JsonData from "./demo.json";
import { useRecoilState } from "recoil";
import { currentPDFData, userInfo } from "app/config/States/users";
import { Modal } from "antd";
import OneImg from "./1st.png";
import TwoImg from "./2nd.png";
import ThreeImg from "./3rd.png";
import { API_ENDPOINT_RECON_HOTEL_NEW } from "app/scenes/AppTour/onboarding.constants";
import { tableSchema } from "app/config/States/agtable";
import { getProcessedDefinition } from "app/utils/generic.helper";

const imageMap: any = {
  "1": OneImg,
  "2": TwoImg,
  "3": ThreeImg,
};
const initialColDef = [
  {
    headerName: "Booking Data",
    children: [
      {
        field: "booking.BookingID",
        headerName: "Booking ID",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.Ticket/PNRNo",
        headerName: "Ticket/PNR No",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.CreatedDate",
        headerName: "Created Date",
        filter: "agDateColumnFilter",
        ...handleCellRenderer(),
        formatType: "DATE",
      },
      {
        field: "booking.UpdatedDate",
        headerName: "Updated Date",
        ...handleCellRenderer(),
        filter: "agDateColumnFilter",
        formatType: "DATE",
      },
      {
        field: "booking.CheckinDate",
        headerName: "Check-in Date",
        filter: "agDateColumnFilter",
        ...handleCellRenderer(),
        formatType: "DATE",
      },
      {
        field: "booking.CheckoutDate",
        headerName: "Check-out Date",
        filter: "agDateColumnFilter",
        ...handleCellRenderer(),
        formatType: "DATE",
      },
      {
        field: "booking.BookerEmail",
        headerName: "Booker Email",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.BookerName",
        headerName: "Booker Name",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.BookerEmployeeID",
        headerName: "Booker Employee ID",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.BookerDepartment",
        headerName: "Booker Department",
        ...handleCellRenderer(),
        filter: "agTextColumnFilter",
      },
      {
        field: "booking.BookerComment",
        headerName: "Booker Comment",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.NoofGuests",
        headerName: "No of Guests",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.NoofAdults",
        headerName: "No of Adults",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.NoofChildren",
        headerName: "No of Children",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.GuestEmail(s)",
        headerName: "Guest Email(s)",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.GuestName(s)",
        headerName: "Guest Name(s)",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.GuestEmployeeID(s)",
        headerName: "Guest Employee ID(s)",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.GuestDepartment(s)",
        headerName: "Guest Department(s)",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.ApproverEmail",
        headerName: "Approver Email",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.ApproverName",
        headerName: "Approver Name",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.ApproverEmployeeID",
        headerName: "Approver Employee ID",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.ApproverComment",
        headerName: "Approver Comment",
        ...handleCellRenderer(),
        filter: "agTextColumnFilter",
      },
      {
        field: "booking.ApproverDepartment",
        headerName: "Approver Department",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.ApprovalStatus",
        headerName: "Approval Status",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.PolicyStatus",
        headerName: "Policy Status",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.Outofpolicyreason",
        headerName: "Out of Policy Reason",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.BookerCostCenter",
        headerName: "Booker Cost Center",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.TravellerCostCenter",
        headerName: "Traveller Cost Center",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.Hotelcountry",
        headerName: "Hotel Country",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        enableRowGroup: true,
      },
      {
        field: "booking.HotelCity",
        headerName: "Hotel City",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        enableRowGroup: true,
      },
      {
        field: "booking.Tier",
        headerName: "Tier",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        enableRowGroup: true,
        enablePivot: true,
      },
      {
        field: "booking.HotelName",
        headerName: "Hotel Name",
        ...handleCellRenderer(),
        filter: "agTextColumnFilter",
      },
      {
        field: "booking.HotelStarRating",
        headerName: "Hotel Star Rating",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.MealPlan",
        headerName: "Meal Plan",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.RoomType",
        headerName: "Room Type",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.BookingAction",
        headerName: "Booking Action",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.BookingMode",
        headerName: "Booking Mode",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.NoofNights",
        headerName: "No of Nights",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.NoofRooms",
        headerName: "No of Rooms",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.IsPayatHotel",
        headerName: "Is Pay at Hotel",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.myBizAssured",
        headerName: "MyBiz Assured",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.HotelAccommodationcharges",
        headerName: "Hotel Accommodation Charges",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.HotelGST",
        headerName: "Hotel GST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.HotelCGST",
        headerName: "Hotel CGST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.HotelSGST",
        headerName: "Hotel SGST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.HotelIGST",
        headerName: "Hotel IGST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.ConveniencefeechargedbyMMT(includingtax)",
        headerName: "Convenience Fee Charged by MMT (including tax)",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.TransactionHandlingCharge",
        headerName: "Transaction Handling Charge",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.GSTChargedbyMMT",
        headerName: "GST Charged by MMT",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.CGSTchargedbyMMT",
        headerName: "CGST Charged by MMT",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.SGSTchargedbyMMT",
        headerName: "SGST Charged by MMT",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.IGSTchargedbyMMT",
        headerName: "IGST Charged by MMT",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.E-couponamount",
        headerName: "E-coupon Amount",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.CancellationPenalty",
        headerName: "Cancellation Penalty",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.Netamountcharged",
        headerName: "Net Amount Charged",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "booking.IsGSTClaimable",
        headerName: "Is GST Claimable",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.GSTClaimableAmount",
        headerName: "GST Claimable Amount",
        ...handleCellRenderer(),

        filter: "agNumberColumnFilter",
        formatType: "AMOUNT",
      },
      {
        field: "booking.AdvanceReceiptNumber",
        headerName: "Advance Receipt Number",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.MMTInvoiceNo",
        headerName: "MMT Invoice No",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.VendorInvoiceNo",
        headerName: "Vendor Invoice No",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.MMTGSTN",
        headerName: "MMT GSTN",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.HotelGSTN",
        headerName: "Hotel GSTN",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.CustomerGSTN",
        headerName: "Customer GSTN",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.PaymentSource",
        headerName: "Payment Source",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.ClaimReason",
        headerName: "Claim Reason",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.ExpenseCode",
        headerName: "Expense Code",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.OldBookingID",
        headerName: "Old Booking ID",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.Non-OfficialBooking",
        headerName: "Non-Official Booking",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.UniqueID",
        headerName: "Unique ID",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.RequisitionID",
        headerName: "Requisition ID",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.PartnerTripID",
        headerName: "Partner Trip ID",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.ReasonForTravel",
        headerName: "Reason For Travel",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.HotelierServiceCharge",
        headerName: "Hotelier Service Charge",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "booking.HotelFareType",
        headerName: "Hotel Fare Type",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
    ],
  },

  {
    headerName: "Invoice Data",
    children: [
      {
        field: "invoice.invoiceDate",
        headerName: "Invoice Date",
        filter: "agDateColumnFilter",
        ...handleCellRenderer(),
        formatType: "DATE",
      },
      {
        field: "invoice.action",
        headerName: "Action",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.invoiceNo",
        headerName: "Invoice No",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.paymentMethod",
        headerName: "Payment Method",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },

      // Nested parsedinvoice fields
      {
        field: "invoice.parsedinvoice.gstamount",
        headerName: "GST Amount",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "invoice.parsedinvoice.gstrate",
        headerName: "GST Rate",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.parsedinvoice.hoteladdress",
        headerName: "Hotel Address",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.parsedinvoice.hotelname",
        headerName: "Hotel Name",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        enableRowGroup: true,
        formatType: "ENTITY",
      },
      {
        field: "invoice.parsedinvoice.buyername",
        headerName: "Buyer Name",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.parsedinvoice.sellerphone",
        headerName: "Seller Phone",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.parsedinvoice.guestgstin",
        headerName: "Guest GSTIN",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.parsedinvoice.invoicedate",
        headerName: "Invoice Date",
        filter: "agDateColumnFilter",
        formatType: "DATE",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.parsedinvoice.totaltaxamount",
        headerName: "Total Tax Amount",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "invoice.parsedinvoice.totaltax%",
        headerName: "Total Tax %",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.parsedinvoice.subtotal",
        headerName: "Subtotal",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "invoice.parsedinvoice.invoiceamount",
        headerName: "Invoice Amount",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
      },
      {
        field: "invoice.parsedinvoice.hotelgstin",
        headerName: "Hotel GSTIN",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.parsedinvoice.invoicenumber",
        headerName: "Invoice Number",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
      {
        field: "invoice.parsedinvoice.hotelemail",
        headerName: "Hotel Email",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
      },
    ],
  },
];

const ChildComponent = forwardRef((props: any, ref) => {
  const [pdfViewerData, setPdfViewerData] = useState("");
  const [_, setCurrentPDFData] = useRecoilState(currentPDFData);
  const [showModal, setShowModal] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [tableSchemaInfo, setTableSchema] = useRecoilState<any>(tableSchema);
  const [recoilPDFData, setRecoilPdfData] =
    useRecoilState<string>(currentPDFData);

  const openSidebarRenderer = (params: any) => {
    console.log("pdf data:", params);
    setCurrentPDFData(params?.data);
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };

  const gridRef: any = useRef();

  // { headerName: "Invoice", field: "Invoice", filter: "agTextColumnFilter" },
  // {
  //   headerName: "INVOICE LINK",
  //   field: "INVOICE_LINK",
  //   filter: "agTextColumnFilter",
  // },

  const columnDefs = [
    {
      headerName: "Invoice",
      field: "invoice.invoiceUrl",
      filter: "agTextColumnFilter",
      invoiceLink: "html.pdf",
      cellRenderer: ToolParamInvoker,
      cellRendererParams: (params: any) => {
        return {
          label: "View Invoice",
          handleClick: openSidebarRenderer,
          data: params.data?.INVOICE_LINK || params?.value,
        };
      },
    },

    ...initialColDef,
  ];

  const processColumnDefs = () => {
    const columnObject = tableSchemaInfo.find(
      (item: any) => item.moduleId === "HOTEL_RECON"
    );

    let columnArray = columnObject?.state?.columnDefs || [];
    let finalDefs = getProcessedDefinition(
      columnArray,
      "SERVER",
      setRecoilPdfData
    );
    console.log("final Defs:", finalDefs);
    return finalDefs;
  };

  useImperativeHandle(ref, () => ({
    createNewView(viewName: string) {
      gridRef.current?.saveGridView(viewName, props.moduleId);
    },
    loadTableView(viewId: string) {
      gridRef.current?.loadGridView(viewId, props.moduleId);
    },
    updateTableView(viewId: string) {
      gridRef.current?.updateGridView(viewId, props.moduleId);
    },
    exportDataToExcel() {
      gridRef.current?.exportDataToExcel();
    },
  }));

  return (
    <div className="FlightRecon" style={{ height: "100%" }}>
      <AgTableServer
        /* @ts-ignore */
        columnDefs={processColumnDefs()}
        rowData={JsonData}
        groupPanel
        enablePdfViewer
        pdfDataKey="invoiceUrl"
        endpoint={API_ENDPOINT_RECON_HOTEL_NEW}
        pdfV2
        grandTotalRow
        ref={gridRef}
        useWorkspace={
          userDetails?.currentWorkspace?.name === "MMT Admin" ? false : true
        }
      />

      <Modal
        closable={false}
        onCancel={() => setShowModal("")}
        open={showModal !== ""}
        footer={false}
        width={800}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <img
            src={imageMap[showModal]}
            alt="Image"
            style={{ objectFit: "contain", width: "100%" }}
          />
        </div>
      </Modal>
    </div>
  );
});

export default ChildComponent;
