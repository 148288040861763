import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "../AgTable.scss";
import { Table } from "antd";
const MasterDetail = ({ data }) => {
  const columnDefs1 = [
    { field: "invoiceNo", headerName: "Invoice Number" },
    { field: "invoiceDate", headerName: "Invoice Date" },
    { field: "AckNo", headerName: "Ack No " },

    // Add columns for the first detail grid
  ];

  return (
    <div
      style={{
        height: 400,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 24,
        paddingLeft: 12,
        paddingRight: 12,
        boxSizing: "border-box",
      }}
    >
      <div className="ag-theme-alpine" style={{ height: 250, marginRight: 12 }}>
        <AgGridReact
          columnDefs={columnDefs1}
          rowData={[
            {
              invoiceNo: "FPK232",
              invoiceDate: "23 Jan 2023",
              AckNo: "SDF@@##",
            },
            {
              invoiceNo: "FPK232",
              invoiceDate: "23 Jan 2023",
              AckNo: "SDF@@##",
            },
          ]}
        />
      </div>
      <div className="ag-theme-alpine" style={{ height: 250, marginRight: 12 }}>
        <AgGridReact
          columnDefs={columnDefs1}
          rowData={[
            {
              invoiceNo: "FPK232",
              invoiceDate: "23 Jan 2023",
              AckNo: "SDF@@##",
            },
            {
              invoiceNo: "FPK232",
              invoiceDate: "23 Jan 2023",
              AckNo: "SDF@@##",
            },
          ]}
        />
      </div>
      <div className="ag-theme-alpine" style={{ height: 250, width: "25%" }}>
        <AgGridReact
          columnDefs={columnDefs1}
          rowData={[
            {
              invoiceNo: "FPK232",
              invoiceDate: "23 Jan 2023",
              AckNo: "SDF@@##",
            },
            {
              invoiceNo: "FPK232",
              invoiceDate: "23 Jan 2023",
              AckNo: "SDF@@##",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default MasterDetail;
