import { atom } from "recoil";

export const tableSchema: any = atom({
  key: "tableSchema",
  default: {},
});

export const agTableRowSize: any = atom({
  key: "agTableRowSize",
  default: 100,
});
