import PageHeader from "app/shared/PageHeader";
import "./SchemaColmapHistory.scss";
import Uploader from "app/shared/Uploader";

import { useEffect, useState } from "react";
import { uploadInfo, userInfo } from "app/config/States/users";
import GlobalUploader from "app/shared/GlobalUploader";
import { useRecoilState } from "recoil";
import AgTableClient from "app/shared/AgTable/AgTableClient";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_STORAGE_OBJECTS_LOGS } from "../../finkanalytics.constants";

const columnDefs = [
  {
    field: "file_id",
    headerName: "File ID",
  },
  {
    field: "schema_type",
    headerName: "Schema Type",
  },
  {
    field: "filename",
    headerName: "File Name",
  },
  {
    field: "status",
    headerName: "Status",
  },
];
export default function SchemaColmapHistory(props: any) {
  const [_, setUploadInfo] = useRecoilState(uploadInfo);
  const [rowData, setRowData] = useState<any[]>([]);

  useEffect(() => {
    handleFetchRowData();
  }, []);
  const handleFetchRowData = async () => {
    const response = await apiGet(API_ENDPOINT_STORAGE_OBJECTS_LOGS);
    if (response.status) {
      setRowData(response.data);
    }
  };
  const handleFileUpload = (file: any) => {
    setUploadInfo(file);
  };
  return (
    <div className="SchemaColmapHistory">
      <div className="Header">
        <PageHeader title="File Upload History" />
      </div>

      <div className="ContentContainer">
        <AgTableClient
          // @ts-ignore
          rowData={rowData}
          columnDefs={columnDefs}
          pdfDataKey="S3 Link"
          autoResize
          // autoGroupColumnDef={["Vendor Name"]}
        />
      </div>
    </div>
  );
}
