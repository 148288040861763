import TextField from "app/shared/TextField";
import React, { useState } from "react";
import "./InviteMember.scss";
import { Avatar, Button, Popover, Select, Tag, message } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";
import {
  EditOutlined,
  CloseOutlined,
  DeleteTwoTone,
  ClusterOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import MemberSearch from "app/shared/MemberSearch";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_SETTING_INVITE_MEMBER } from "../../settings.constants";
const OPTIONS = [
  "Apples",
  "Nails",
  "Bananas",
  "Helsicopters",
  "Appales",
  "Naisls",
  "Bansanas",
  "Heliacopters",
];
// const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

export default function InviteMember(props: any) {
  const [members, setMembers] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showEdit, setShowEdit] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [allowedDomains, setAllowedDomains] = useState<any[]>([]);
  const [designation, setDesignation] = useState("");
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleInviteMember = async () => {
    let payload: any = [];
    setLoading(true);

    members.forEach((memberInfo: any) => {
      payload.push({
        email: memberInfo.email,
        workspaces: memberInfo.workspaces,
      });
    });
    if (payload.length === 0 || selectedWorkspaces.length === 0) {
      messageApi.error({
        type: "error",
        content: "Please provide information for invite",
      });
      setLoading(false);
      return;
    }
    const response = await apiPost(API_ENDPOINT_SETTING_INVITE_MEMBER, {
      userList: payload,
      inviteType: "CLEARTRIP",
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleOptionSelect = (value: any, fullObj: any) => {
    let emailDomain = fullObj.email.split("@")[1];
    if (allowedDomains.includes(emailDomain) || allowedDomains.length === 0) {
      let memberAccess = {
        ...fullObj,
        workspaces: selectedWorkspaces,
      };
      setMembers([...members, memberAccess]);
    } else {
      messageApi.error({
        type: "error",
        content: `Domain not allowed for ${emailDomain}`,
      });
    }
  };

  const handleRemoveMember = (id: string) => {
    const updatedData = members.filter((item) => item.id !== id);
    setMembers(updatedData);
  };

  const { Option } = Select;

  const handleSelectWorkspace = (spaceInfo: any, fullObj: any) => {
    console.log("workspace info", fullObj);

    setAllowedDomains(fullObj.length > 0 ? fullObj?.[0]?.domain : []);
    setSelectedWorkspaces(spaceInfo);
  };

  return (
    <div className="InviteMember">
      <div className="AddUserContainer">
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
            marginBottom: allowedDomains.length > 0 ? 0 : 12,
          }}
          placeholder="Workspace"
          defaultValue={[]}
          onChange={handleSelectWorkspace}
          // options={(userDetails.workspaces || []).map((item: any) => ({
          //   value: item.id,
          //   label: item.name,
          // }))}
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            option.children.toLowerCase().includes(input.toLowerCase()) ||
            option.value.toLowerCase().includes(input.toLowerCase())
          }
        >
          {userDetails.workspaces.map((opt: any) => (
            <Option
              key={opt.id}
              value={opt.id}
              meta={opt.meta}
              domain={opt.domains}
            >
              {opt.name}
            </Option>
          ))}
        </Select>

        {allowedDomains.length > 0 ? (
          <div style={{ marginTop: 12, marginBottom: 24 }}>
            <Typography
              variant="caption"
              style={{ color: colorPicker("neutral.800") }}
            >
              Allowed Domains
            </Typography>
            <div style={{ marginTop: 4 }}>
              {(allowedDomains || []).map((domain: any, key: any) => {
                return domain === "kgrp.in" ||
                  domain === "shopelect.com" ? null : (
                  <Tag key={key} color="green" icon={<GlobalOutlined />}>
                    {domain}
                  </Tag>
                );
              })}
            </div>
          </div>
        ) : null}

        {/* <div style={{ marginBottom: 18 }}>
          <TextField
            style={{ height: 34 }}
            placeholder="Designation"
            onChange={(e: any) => setDesignation(e.target.value)}
          />
        </div> */}

        {/* <TextField
          placeholder="Email"
          style={{ height: 34 }}
          onPressEnter={(e: any) => handleAddMembers(e.target.value)}
        /> */}
        <Typography
          variant="xs"
          style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
        >
          Email
        </Typography>
        <MemberSearch onOptionSelect={handleOptionSelect} />
      </div>

      {members.length > 0 ? (
        <>
          <div className="MemberList">
            <Typography
              weight={500}
              style={{ color: colorPicker("neutral.800"), marginBottom: 12 }}
            >
              Members
            </Typography>
            {members.map((member: any, key: any) => {
              return (
                <div className="MemberItem" key={key}>
                  <div className="ItemLeft">
                    <Avatar
                      style={{
                        backgroundColor: colorPicker("primary.700"),
                        verticalAlign: "middle",
                      }}
                      size={32}
                    >
                      {member.email[0]}
                    </Avatar>
                    <div>
                      {member.name === "EXTERNAL" ? (
                        <Typography
                          variant="xs"
                          style={{
                            marginLeft: 6,
                            color: colorPicker("primary.700"),
                          }}
                          weight={600}
                        >
                          EXTERNAL
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            marginLeft: 6,
                            color: colorPicker("neutral.900"),
                          }}
                          variant="caption"
                        >
                          {member.name}
                        </Typography>
                      )}
                      <Typography
                        style={{
                          marginLeft: 6,
                          color: colorPicker("neutral.700"),
                        }}
                        variant="xs"
                      >
                        {member.email}
                      </Typography>
                    </div>
                  </div>
                  <div className="ItemRight">
                    <Tag icon={<ClusterOutlined />}>
                      {designation || "Member"}
                    </Tag>
                    {/* {showEdit === key ? (
                      <div>
                        <Select
                          mode="multiple"
                          placeholder="Selected Workspace"
                          showSearch
                          value={selectedItems}
                          optionFilterProp="children"
                          // maxTagCount={1}
                          onChange={setSelectedItems}
                          style={{ width: 200 }}
                          // options={OPTIONS.map((item) => ({
                          //   value: item,
                          //   label: item,
                          // }))}
                          size="small"
                          filterOption={(input, option: any) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase()) ||
                            option.value
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {OPTIONS.map((opt: any) => (
                            <Option key={opt} value={opt}>
                              {opt}
                            </Option>
                          ))}
                        </Select>
                        <CloseOutlined
                          onClick={() => setShowEdit("")}
                          style={{ marginLeft: 6 }}
                        />
                      </div>
                    ) : (
                      <Popover
                        content={
                          <div>
                            {member.workspaces.map(
                              (workspace: any, wkey: any) => {
                                return (
                                  <Tag
                                    key={wkey}
                                    color={colorPicker("primary.700")}
                                  >
                                    {
                                      userDetails.workspaces.find(
                                        (wInfo: any) => wInfo.id === workspace
                                      )?.name
                                    }
                                  </Tag>
                                );
                              }
                            )}
                          </div>
                        }
                      >
                        <Typography
                          variant="xs"
                          weight={500}
                          style={{
                            color: colorPicker("primary.700"),
                            cursor: "pointer",
                          }}
                        >
                          {member.workspaces.length}{" "}
                          {member.workspaces.length === 1
                            ? "workspace"
                            : "workspaces"}{" "}
                          <EditOutlined onClick={() => setShowEdit(key)} />
                        </Typography>
                      </Popover>
                    )} */}

                    <DeleteTwoTone
                      style={{
                        marginLeft: 24,
                        cursor: "pointer",
                      }}
                      twoToneColor={colorPicker("red.700")}
                      onClick={() => handleRemoveMember(member.id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}

      <div className="BottomAction">
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          type="primary"
          style={{ marginLeft: 6 }}
          onClick={handleInviteMember}
          loading={isLoading}
        >
          Add
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
