import { useCallback, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";

const jsonData = [
  {
    vendor: "All Nippon Airways",
    Type: "International Carriers",
    BookingData: "02-11-2019",
    ticketNumber: "9479261341",
    PNR: "WZRKH9",
    BookingAmount: 36171,
    K3Amount: 1722,
    traveller: "MR Gameboy",
    Customer_GSTIN: "hidden",
    agency_invoice_no: "ADH0120T0030972",
    total_data: "IRN",
    dataType: "Source",
    childrens: [
      {
        bookingStatus: "MATCH",
        "2BStatus": "MATCH",
        totalInvoice: 3,
        filedId: "Date",
      },
      {
        name: "susan",
        callId: 556,
        duration: 61,
        switchCode: "SW3",
        direction: "In",
        number: "(01) 7432576",
        filedId: "Date",
      },
      {
        bookingStatus: "MATCH",
        "2BStatus": "MATCH",
        totalInvoice: 3,
        filedId: "Amount",
      },
      {
        name: "susan",
        callId: 556,
        duration: 61,
        switchCode: "SW3",
        direction: "In",
        number: "(01) 7432576",
        filedId: "Amount",
      },
    ],
  },
  {
    vendor: "All Nippon Airways",
    Type: "International Carriers",
    BookingData: "02-11-2019",
    ticketNumber: "9479261341",
    PNR: "WZRKH9",
    BookingAmount: 36171,
    K3Amount: 1722,
    traveller: "MR Gameboy",
    Customer_GSTIN: "hidden",
    agency_invoice_no: "ADH0120T0030972",
    total_data: "2B",
    dataType: "Filtered Matched",
    childrens: [
      {
        bookingStatus: "MATCH",
        "2BStatus": "MATCH",
        totalInvoice: 3,
        filedId: "Date",
      },
      {
        name: "susan",
        callId: 556,
        duration: 61,
        switchCode: "SW3",
        direction: "In",
        number: "(01) 7432576",
        filedId: "Date",
      },
      {
        bookingStatus: "MATCH",
        "2BStatus": "MATCH",
        totalInvoice: 3,
        filedId: "Amount",
      },
      {
        name: "susan",
        callId: 556,
        duration: 61,
        switchCode: "SW3",
        direction: "In",
        number: "(01) 7432576",
        filedId: "Amount",
      },
    ],
  },
  {
    vendor: "All Nippon Airways",
    Type: "International Carriers",
    BookingData: "02-12-2020",
    ticketNumber: "94261341",
    PNR: "WRRKH9",
    BookingAmount: 16171,
    K3Amount: 1222,
    traveller: "MR Gameboy",
    Customer_GSTIN: "hidden",
    agency_invoice_no: "ADH0120T0030972",
    total_data: "Invoice/Booking",
    dataType: "Filtered Mismatched",
    childrens: [
      {
        bookingStatus: "MATCH",
        "2BStatus": "MATCH",
        totalInvoice: "View Invoices",
        filedId: "Total Amount",
      },
      {
        name: "susan",
        callId: 556,
        duration: 61,
        switchCode: "SW3",
        direction: "In",
        number: "(01) 7432576",
        filedId: "Total Amount",
      },
    ],
  },
  {
    vendor: "Vistara",
    Type: "International Carriers",
    BookingData: "02-12-2020",
    ticketNumber: "94261341",
    PNR: "WRRKH9",
    BookingAmount: 16171,
    K3Amount: 1222,
    traveller: "MR Gameboy",
    Customer_GSTIN: "hidden",
    agency_invoice_no: "ADH0120T0030972",
    total_data: "Invoice/Booking",
    dataType: "Filtered Mismatched",
    childrens: [
      {
        bookingStatus: "MATCH",
        "2BStatus": "MATCH",
        totalInvoice: "View Invoices",
        filedId: "Total Amount",
      },
      {
        name: "susan",
        callId: 556,
        duration: 61,
        switchCode: "SW3",
        direction: "In",
        number: "(01) 7432576",
        filedId: "Total Amount",
      },
    ],
  },
];
const AgTestMaster = () => {
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-GB"); // Format as 'dd-mm-yyyy'
  }

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Vendor",
      field: "vendor",
      rowGroup: true,
      hide: true,
      filter: "agTextColumnFilter", // Text filter for string data
    },
    {
      headerName: "Data Type",
      field: "dataType",
      rowGroup: true,
      hide: true,
    },
    {
      headerName: "Total Data",
      field: "total_data",
      // rowGroup: true,
      hide: true,
    },
    {
      headerName: "Filtered Matched",
      field: "Filtered Matched",
      hide: true,
    },
    {
      headerName: "Filtered MisMatched",
      field: "Filtered MisMatched",
      hide: true,
    },
    {
      headerName: "Type",
      field: "Type",
      filter: "agTextColumnFilter", // Text filter for string data
    },
    {
      headerName: "Booking Date",
      field: "BookingData",
      filter: "agDateColumnFilter", // Date filter for date data
      // valueFormatter: (params) => formatDate(params.value), // Optionally format the date
    },
    {
      headerName: "Ticket Number",
      field: "ticketNumber",
      filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
    },
    {
      headerName: "PNR",
      field: "PNR",
      filter: "agTextColumnFilter", // Text filter for string data
    },
    {
      headerName: "Booking Amount",
      field: "BookingAmount",
      filter: "agNumberColumnFilter", // Number filter for numerical data
    },
    {
      headerName: "K3 Amount",
      field: "K3Amount",
      filter: "agNumberColumnFilter", // Number filter for numerical data
    },
    {
      headerName: "Traveller",
      field: "traveller",
      filter: "agTextColumnFilter", // Text filter for string data
    },
    {
      headerName: "Customer GSTIN",
      field: "Customer_GSTIN",
      filter: "agTextColumnFilter", // Text filter for string data
    },
    {
      headerName: "Agency Invoice No.",
      field: "agency_invoice_no",
      filter: "agTextColumnFilter", // Text filter for string data
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      width: 300,
    };
  }, []);
  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            field: "filedId",
            headerName: "Matched Fields",
            rowGroup: true,
          },
          {
            headerName: "Booking",
            children: [
              {
                field: "bookingStatus",
                headerName: "Match Status",
                columnGroupShow: ["open", "close"],
                filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
              },
              {
                field: "Airline Name ",
                columnGroupShow: "open",
                filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
              },
              {
                field: "Booking Column3 ",
                columnGroupShow: "open",
                filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
              },
            ],
          },
          {
            headerName: "2B",
            children: [
              {
                field: "2BStatus",
                headerName: "Match Status",
                columnGroupShow: ["open", "close"],
                filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
              },

              {
                field: "2B Column2 ",
                columnGroupShow: "open",
                filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
              },
              {
                field: "2B Column3 ",
                columnGroupShow: "open",
                filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
              },
            ],
          },
          {
            headerName: "Invoices",
            children: [
              {
                field: "totalInvoice",
                headerName: "Total Invoice",
                columnGroupShow: ["open", "close"],
                filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
              },
              {
                field: "Invoice Column2 ",
                columnGroupShow: "open",
                filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
              },
              {
                field: "Invoice Column3 ",
                columnGroupShow: "open",
                filter: "agTextColumnFilter", // Text filter for string data (ticket numbers treated as strings)
              },
            ],
          },
        ],
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.childrens);
      },
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/master-detail-data.json")
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(data);
      });
  }, []);

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact
          rowData={jsonData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={true}
          embedFullWidthRows={true}
          detailCellRendererParams={detailCellRendererParams}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};

export default AgTestMaster;
