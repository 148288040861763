import React, { useEffect, useState } from "react";
import "./HotelDashboard.scss";
import PageHeader from "app/shared/PageHeader";
import { AgTableClient } from "app/shared/AgTable";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import EmptyState from "app/shared/EmptyState";
export default function HotelDashboard(props: any) {
  const [userDetails, _] = useRecoilState<any>(userInfo);

  return (
    <div className="FlightDashboard" style={{ height: "100%" }}>
      {userDetails?.currentWorkspace?.name === "MMT Admin" ||
      userDetails?.currentWorkspace?.meta?.zoho_workspace_name ? (
        <iframe
          height="100%"
          style={{ width: "100%" }}
          scrolling="no"
          title="Iframe"
          src={`https://analytics.zoho.in/open-view/103074000028634802/348eaf95608aa48ea99e1bbbc543d5c5${
            userDetails?.currentWorkspace?.name === "MMT Admin"
              ? ``
              : `?ZOHO_CRITERIA="workspace"%3D%27${userDetails?.currentWorkspace?.meta?.zoho_workspace_name}%27`
          }`}
        ></iframe>
      ) : (
        <EmptyState />
      )}
    </div>
  );
}
