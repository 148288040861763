import React, { useState, useEffect, useRef } from "react";
import MicNoneIcon from "@mui/icons-material/MicNone";
import MicIcon from "@mui/icons-material/Mic";
import { colorPicker } from "app/utils/color.helper";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

const isSpeechSupportd = navigator;
mic.continuous = false;
mic.interimResults = true;
mic.lang = "en-US";

function SpeechToText(props) {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const recognitionRef = useRef(false); // Keeps track of recognition state
  const inactivityTimeoutRef = useRef(null); // Keeps track of the inactivity timeout
  const latestTranscriptRef = useRef(""); // Keeps track of latest transcript

  const maxRetries = 3; // Set a limit for retries
  const inactivityTimeout = 2000; // 2 seconds of inactivity to detect pause

  useEffect(() => {
    handleListen();
    return () => {
      // Clear timeout if the component unmounts
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      mic.stop();
    };
  }, [isListening]);

  const handleListen = () => {
    if (isListening && !recognitionRef.current) {
      try {
        mic.start();
        recognitionRef.current = true; // Update the recognition state
        setRetryCount(0); // Reset retry count on successful start
      } catch (e) {
        console.error("Failed to start microphone: ", e);
      }
    } else if (!isListening && recognitionRef.current) {
      mic.stop();
      console.log("Mic is stopped");
      recognitionRef.current = false; // Update the recognition state
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current); // Clear the inactivity timeout
      }
      inactivityTimeoutRef.current = null; // Reset the inactivity timeout ref
      setTranscript(""); // Clear the transcript
    }

    mic.onend = () => {
      recognitionRef.current = false; // Update the recognition state
      if (isListening && retryCount < maxRetries) {
        console.log("Mic is ended. Restarting...");
        setRetryCount((count) => count + 1);
        mic.start();
      }
    };

    mic.onstart = () => {
      console.log("Mic is on");
    };

    mic.onresult = (event) => {
      const interimTranscript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      setTranscript(interimTranscript);

      // Update latest transcript
      const finalTranscript = interimTranscript.trim();
      if (finalTranscript) {
        latestTranscriptRef.current = finalTranscript;
        props.onSpeech(finalTranscript);
      }

      // Reset the inactivity timeout whenever we get a result
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      inactivityTimeoutRef.current = setTimeout(() => {
        if (latestTranscriptRef.current.trim()) {
          // Call the callback function with the latest transcript
          props.onInactivity(latestTranscriptRef.current);
        }
        setTranscript(""); // Clear the transcript after passing it to the callback
        console.log("Cleared transcript due to inactivity");
      }, inactivityTimeout);
    };

    mic.onerror = (event) => {
      console.error("Error occurred: ", event.error);
      if (event.error === "network" && retryCount < maxRetries) {
        setRetryCount((count) => count + 1);
        mic.start();
      } else {
        setError(event.error);
        setIsListening(false);
      }
    };
  };

  return isSpeechSupportd ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      {isListening ? (
        <div className="ripple-circle" onClick={() => setIsListening(false)}>
          <MicIcon
            style={{
              fontSize: 16,
              fill: "white",
              cursor: "pointer",
            }}
          />
        </div>
      ) : (
        <MicNoneIcon
          style={{
            fontSize: 20,
            fill: colorPicker("neutral.800"),
            cursor: "pointer",
          }}
          onClick={() => setIsListening(true)}
        />
      )}
    </div>
  ) : null;
}

export default SpeechToText;
