import PageHeader from "app/shared/PageHeader";
import "./HotelBookingView.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import PendingIcon from "@mui/icons-material/Pending";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import { AgTableClient } from "app/shared/AgTable";
import { Button, Modal, Tag, Tooltip, message } from "antd";
import {
  CloudUploadOutlined,
  PhoneFilled,
  MailOutlined,
  CheckCircleFilled,
  WarningFilled,
  PlusOutlined,
  InfoCircleOutlined,
  PercentageOutlined,
  MailFilled,
  EditFilled,
  EllipsisOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
  ClockCircleFilled,
  CopyOutlined,
} from "@ant-design/icons";
import Typography from "app/shared/Typography";
import { EmailOutlined } from "@mui/icons-material";
import HotelDataUpdate from "./components/HotelData";
import moment from "moment";
import { apiGet, apiPost } from "app/services/apiServices";
import Handlebars from "handlebars";

import {
  API_ENDPOINT_FETCH_HOTEL_BOOKING,
  API_ENDPOINT_FETCH_TEMPLATE_INFO,
  API_ENDPOINT_TRIGGER_CUSTOM_MAIL,
  CUSTOM_MAIL_SEND_ACTIONS,
} from "./hotel.booking.constants";
import { colorPicker } from "app/utils/color.helper";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";
import { FormattedNumber } from "react-intl";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { hotelBookingActivityType, SINGLE_MAIL_DATA } from "./templateData";
import Loader from "app/shared/Loader";
const modalTitleMap: any = {
  TEMPLATE_VIEW: "Template Preview",
  ADD_HOTEL: "Update Hotel Info",
  ADD_CC: "Add CC Emails",
};

const colorOfEmailEvent: any = {
  processed: colorPicker("primary.700"),
  delivered: colorPicker("primary.700"),
  open: colorPicker("primary.700"),
  click: colorPicker("primary.700"),
  bounce: "#e74c3c",
  dropped: "#e74c3c",
  spammed: "#e74c3c",
  deffered: "#e74c3c",
};
const EVENT_ICON_MAP = {
  processed: (color: any) => (
    <CheckOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  delivered: (color: any) => (
    <DoneAllOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  open: (color: any) => (
    <DraftsOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  click: (color: any) => (
    <AdsClickOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  acknowledge: (color: any) => (
    <AssignmentTurnedInIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
        marginRight: 28,
      }}
    />
  ),

  UNSUBSCRIBE: (color: any) => (
    <UnsubscribeOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  SPAMMED: (color: any) => (
    <PrivacyTipOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  bounce: (color: any) => (
    <CancelOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),

  dropped: (color: any) => (
    <MarkunreadMailboxOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),

  DEFFERED: (color: any) => (
    <ScheduleSendOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
};

function formatDate(timestamp: any) {
  // Convert Unix timestamp to milliseconds
  const date = new Date(timestamp * 1000);

  // Use Moment.js to format the date
  const formattedDate = moment(date).format("DD-MM-YYYY, HH:MM A");

  return formattedDate;
}

const handleRenderEmailEvents = (eventData: any) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {Object.entries(EVENT_ICON_MAP).map((emailEvent: any) => {
        return (
          <div
            style={{
              marginRight: 6,
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="bottom"
              title={
                <div>
                  <Typography
                    style={{ color: "white", marginBottom: 12 }}
                    variant="xs"
                  >
                    {emailEvent[0]}
                  </Typography>
                  {(eventData?.[emailEvent[0]] || []).map((reader: any) => {
                    return (
                      <Tag key={reader.email} style={{ fontSize: 10 }}>
                        {reader.email} at {formatDate(reader.timestamp)}
                      </Tag>
                    );
                  })}
                </div>
              }
            >
              {emailEvent[1](
                eventData?.[emailEvent[0]]
                  ? colorOfEmailEvent[emailEvent[0]]
                  : "rgb(192 194 197)"
              )}
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

const emailSentStatusMap = [
  "BOOKING",
  "CHECKIN",
  "CHECKOUT_1",
  "CHECKOUT_2",
  "COMPLETED",
  "MONTH_END",
  "BEFORE_GSTR",
  "AFTER_GSTR",
];

const emailSentStatusTitle: any = {
  BOOKING: "Email for Booking Done",
  CHECKIN: "Email for check-in",
  CHECKOUT_1: "Email for checkout reminder 5 hours before",
  CHECKOUT_2: "Email for checkout reminder 2 hours before",
  COMPLETED: "Email to notify about checkout done",
  MONTH_END: "Month End Report Email",
  BEFORE_GSTR: "Email consolidated before GSTR",
  AFTER_GSTR: "Email consolidated after GSTR",
};
export default function HotelBookingView(props: any) {
  const [activeKey, setActiveKey] = useState(1);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [showModal, setShowModal] = useState("");
  const [bookingList, setBookingList] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [templateData, setTemplateData] = useState(null);
  const [templateDataBulk, setTemplateDataBulk] = useState(null);
  const [viewTemplate, setViewTemplate] = useState<any>();
  const [templateRowData, setTemplateRowData] = useState<any>();

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    fetchTemplateInfo();
    fetchTemplateInfoBulk();
  }, []);
  const fetchTemplateInfo = async () => {
    const response = await apiGet(
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-21ea8040c7c04a5595d073b2870fc7c9"
    );
    if (response) {
      setTemplateData(response.html_content);
    }
  };
  const fetchTemplateInfoBulk = async () => {
    const response = await apiGet(
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-a4a07a17c35d4b96ac6e89c84ad3463c"
    );
    if (response) {
      setTemplateDataBulk(response.html_content);
    }
  };

  const getRedFlag = (rowData: any) => {
    if (
      !rowData?.hotel_details ||
      !rowData?.hotel_details?.hotel_emails ||
      rowData?.hotel_details?.hotel_emails?.length === 0
    ) {
      return (
        <>
          <WarningFilled style={{ marginRight: 6, color: "#e74c3c" }} />
          <Typography variant="xs">No Recipients</Typography>
        </>
      );
    } else if (rowData?.email_events?.BOOKING?.dropped?.length > 0) {
      return (
        <>
          <WarningFilled style={{ marginRight: 6, color: "#e74c3c" }} />
          <Typography variant="xs">Mail Dropped</Typography>
        </>
      );
    } else if (rowData?.email_events?.BOOKING?.bounce?.length > 0) {
      return (
        <>
          <WarningFilled style={{ marginRight: 6, color: "#e74c3c" }} />
          <Typography variant="xs">Bounced</Typography>
        </>
      );
    } else {
      return (
        <>
          <CheckCircleFilled style={{ marginRight: 6, color: "#27ae60" }} />
          <Typography variant="xs">Running</Typography>
        </>
      );
    }
  };

  const getGSTCalculated = (amount: any) => {
    let parsedAmount = parseFloat(amount);
    if (parsedAmount < 1000) {
      return 0;
    } else if (parsedAmount < 7500) {
      return (parsedAmount / 100) * 12;
    } else {
      return (parsedAmount / 100) * 18;
    }
  };
  const handleTriggerMail = async (rowData: any) => {
    const response = await apiPost(API_ENDPOINT_TRIGGER_CUSTOM_MAIL, rowData);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Email Sent!",
      });
      // props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: "Could not updated hotel detail",
      });
    }
  };

  const templateViewMap: any = {
    0: {
      title: "Booking Data Template",
      data: templateData,
      json: SINGLE_MAIL_DATA,
    },
    1: {
      title: "Checkin Email Template",
      data: templateData,
      json: SINGLE_MAIL_DATA,
    },
    2: {
      title: "Checkout Data Template",
      data: templateData,
      json: SINGLE_MAIL_DATA,
    },
    3: {
      title: "Monthly Report Template",
      data: templateDataBulk,
      json: SINGLE_MAIL_DATA,
    },
    4: {
      title: "Before GSTR Template",
      data: templateDataBulk,
      json: SINGLE_MAIL_DATA,
    },
    5: {
      title: "After GSTR Template",
      data: templateDataBulk,
      json: SINGLE_MAIL_DATA,
    },
  };

  const handleOpenInvoices = (links: any) => {
    links.forEach((element: string) => {
      window.open(element, "_blank");
    });
  };
  const getValueOfMailStatus = (data: any) => {
    if (data?.email_events?.BOOKING?.dropped?.length > 0) {
      return "Dropped";
    }
    if (data?.email_events?.BOOKING?.bounce?.length > 0) {
      return "Bounce";
    }

    if (
      data?.hotel_details &&
      data?.hotel_details?.hotel_emails &&
      data?.hotel_details?.hotel_emails?.length > 0
    ) {
      return "Running";
    }
    console.log("bounce or dropped", data?.email_events);

    return "No Recipients";
  };

  const handleCopyData = (copyText: any) => {
    if (navigator.clipboard) {
      // Clipboard API supported
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          console.log("Text copied to clipboard");
          messageApi.success({
            type: "success",
            content: "Copied",
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      // Clipboard API not supported, fallback to older method
      fallbackCopyTextToClipboard(copyText);
    }
  };

  function fallbackCopyTextToClipboard(text: any) {
    // Create a temporary textarea element
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      // Execute the copy command
      document.execCommand("copy");
      console.log("Text copied to clipboard");
      messageApi.success({
        type: "success",
        content: "Copied",
      });
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }

    // Remove the temporary textarea element
    document.body.removeChild(textArea);
  }

  const initialColDef = [
    {
      headerName: "Mail Config Status",
      field: "email_events",
      width: 200,
      enableRowGroup: true,
      // formatType: "CUSTOM_RENDER",

      cellRenderer: (params: any) => {
        console.log("data is :", params);
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {getRedFlag(params?.data)}
          </div>
        );
        return "test";
      },
      valueGetter: (params: any) => {
        console.log("value data:", params.data?.email_events);
        return getValueOfMailStatus(params?.data);
        return "Test";
      },
      formatProps: {
        valueGetterData: (params: any, fullParams: any) =>
          getValueOfMailStatus(params?.data),

        // valueGetterData: (params: any) => getValueOfMailStatus(params),
      },
    },
    {
      field: "from",
      headerName: "Time Left For Checkout",
      width: 200,
      enableRowGroup: true,
      formatType: "CUSTOM_RENDER",
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          let momentDays = moment.duration(
            moment(params?.booking_data?.tripDetails?.tripEndDateTime).diff(
              moment().format("YYYY-MM-DDTHH:mm:ssZ")
            )
          );
          const isCheckedOut = moment().isAfter(
            moment(params?.booking_data?.tripDetails?.tripEndDateTime)
          );
          const isEmailSent =
            Object.keys(params?.email_events || {})?.length > 0;
          const days = Math.floor(momentDays.asDays());
          const hours = Math.floor(momentDays.asHours() % 24);
          const totalHours = Math.floor(momentDays.asHours());

          return (
            <div
              style={{
                // background: hours > 10 || days > 0 ? "green" : "red",
                padding: "0 16px",
              }}
            >
              <Tag
                color={
                  isEmailSent ? "green" : totalHours < 20 ? "red" : "green"
                }
                icon={
                  isCheckedOut ? <CheckCircleFilled /> : <ClockCircleFilled />
                }
              >
                {isCheckedOut
                  ? "Checked Out"
                  : `${days + 1} days ${hours} hours ${
                      hours < 0 || days < 0 ? "passed" : "left"
                    }`}
              </Tag>
            </div>
          );
        },

        valueGetterData: (params: any) => {
          let momentDays = moment.duration(
            moment(params?.booking_data?.tripDetails?.tripEndDateTime).diff(
              moment(moment().format("YYYY-MM-DDTHH:mm:ssZ"))
            )
          );
          return momentDays.asHours();
        },
      },
    },
    // {
    //   field: "lastmail",
    //   headerName: "Last Mail Sent",
    //   cellRenderer: (params: any) => {
    //     return moment().format("DD-MM-YYYY, HH:MM A");
    //   },
    // },

    {
      field: "booking_data",
      headerName: "Template",
      width: 200,
      formatType: "CUSTOM_RENDER",
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          return (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {[0, 1, 2, 3, 4, 5].map((template, key) => {
                return (
                  <div
                    style={{
                      width: 18,
                      height: 18,
                      background: "#34495e",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      color: "white",
                      cursor: "pointer",
                      marginRight: 8,
                    }}
                    onClick={() => {
                      setShowModal("TEMPLATE_VIEW");
                      setViewTemplate(template);
                      setTemplateRowData(params);
                    }}
                    key={key}
                  >
                    {template + 1}
                  </div>
                );
              })}
            </div>
          );
        },
        valueGetterData: (params: any) => params,
      },
    },
    {
      headerName: "Hotel Details",
      children: [
        {
          headerName: "Hotel Contact",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              let hotelInfo = params?.hotel_details;
              return (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setSelectedBooking({
                      ...params,
                      hotel_code:
                        params?.booking_data?.segments?.property?.code,
                    });
                    setShowModal("ADD_HOTEL");
                  }}
                >
                  {/* <MailFilled
                    style={{ marginRight: 10, color: colorPicker("green.700") }}
                  /> */}
                  <MailOutlined
                    style={{
                      marginRight: 10,
                      color: colorPicker(
                        !hotelInfo?.hotel_emails ||
                          hotelInfo?.hotel_emails.length === 0
                          ? "red.700"
                          : "green.700"
                      ),
                    }}
                  />
                  <PhoneFilled
                    style={{
                      marginRight: 10,
                      color: colorPicker(
                        hotelInfo?.hotel_phone?.length === 0
                          ? "red.700"
                          : "green.700"
                      ),
                    }}
                  />
                  <Typography
                    variant="xs"
                    weight={700}
                    style={{
                      fontSize: 11,
                      color: colorPicker(
                        !hotelInfo?.hotel_gstin ? "red.700" : "green.700"
                      ),
                    }}
                  >
                    GSTIN
                  </Typography>

                  <EditFilled style={{ marginLeft: 6, cursor: "pointer" }} />
                </div>
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(Object.keys(params?.hotel_details || {}))
                ? Object.keys(params?.hotel_details || {})
                : [];
            },
          },
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["hotel_gstin", "hotel_emails", "hotel_phone"],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
        },

        {
          field: "hotel_details.hotel_name",
          headerName: "Hotel Name",
          width: 300,
          enableRowGroup: true,
          // valueGetter: (params: any) => params.data?.hotel_details?.hotel_name,
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            valueGetterData: (params: any) =>
              params.data?.hotel_details?.hotel_name,
            cellRenderer: (data: any, params: any) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {params?.hotel_details ? (
                    <>
                      <CopyOutlined
                        style={{ marginRight: 6, cursor: "pointer" }}
                        onClick={() =>
                          handleCopyData(params?.hotel_details?.hotel_name)
                        }
                      />
                      <Typography variant="xs" style={{ marginRight: 12 }}>
                        {params?.hotel_details?.hotel_name}
                      </Typography>
                    </>
                  ) : (
                    <Tag
                      icon={<InfoCircleOutlined />}
                      color="red"
                      style={{ cursor: "pointer" }}
                    >
                      Add Hotel Info
                    </Tag>
                  )}
                </div>
              );
            },
          },
          columnGroupShow: "close",
        },
        {
          headerName: "Hotel GSTIN",
          field: "hotel_details.hotel_gstin",
          formatType: "CHIP",
          ...handleCellRenderer(),
          enableRowGroup: true,
        },
        {
          field: "from",
          headerName: "From",
          width: 200,
          cellRenderer: () => "gstinfo@finkraft.ai",
          columnGroupShow: "open",
        },
        {
          field: "to",
          headerName: "To",
          width: 200,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),

          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {params?.hotel_details?.hotel_emails?.map(
                    (hotel_email: any) => {
                      return <Tag icon={<MailOutlined />}>{hotel_email}</Tag>;
                    }
                  )}
                </div>
              );
            },
          },
        },

        {
          field: "cc",
          headerName: "CC",
          width: 200,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {params?.cc_emails?.map((hotel_email: any) => {
                    return <Tag icon={<MailOutlined />}>{hotel_email}</Tag>;
                  })}
                  <Tag
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedBooking(params);
                      setShowModal("ADD_CC");
                    }}
                  >
                    Add Email
                  </Tag>
                </div>
              );
            },
          },
        },

        {
          field: "booking_data.segments.property.address.address1",
          headerName: "Hotel Address",
          width: 200,
          columnGroupShow: "open",
          // formatType: "CUSTOM_RENDER",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            valueGetterData: (params: any) =>
              params.data?.hotel_details?.hotel_name,
            cellRenderer: (data: any, params: any) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <>
                    <CopyOutlined
                      style={{ marginRight: 6, cursor: "pointer" }}
                      onClick={() =>
                        handleCopyData(
                          params?.booking_data?.segments.property.address
                            .address1
                        )
                      }
                    />
                    <Typography variant="xs" style={{ marginRight: 12 }}>
                      {params?.booking_data?.segments.property.address.address1}
                    </Typography>
                  </>
                </div>
              );
            },
          },
        },
        {
          field: "booking_data.segments.property.address.city.name",
          headerName: "Hotel City",
          width: 200,
          columnGroupShow: "open",
          // formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
        },
        {
          field: "booking_data.segments.property.address.region.code",
          headerName: "Hotel State",
          width: 200,
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
        },
      ],
    },

    {
      headerName: "Booking Details",
      children: [
        {
          field: "to",
          headerName: "Email Sent Status",
          width: 250,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          filter: "agSetColumnFilter",
          filterParams: {
            values: emailSentStatusMap,
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },

          valueFormatter: (params: any) =>
            Array.isArray(params.value)
              ? params.value.join(", ")
              : params.value,

          // valueFormatter: (params: any) => params.value.join(", "),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {emailSentStatusMap.map((emailStatusType: any) => {
                    return (
                      <Tooltip
                        title={
                          <div>
                            {emailSentStatusTitle[emailStatusType]}
                            {params?.email_events?.[emailStatusType]?.[
                              "processed"
                            ]
                              ? " at " +
                                formatDate(
                                  params?.email_events?.[emailStatusType]?.[
                                    "processed"
                                  ]?.[0]?.timestamp
                                )
                              : ""}
                          </div>
                        }
                      >
                        {params?.email_events?.[emailStatusType] ? (
                          <CheckCircleFilled
                            style={{
                              fontSize: 17,
                              marginRight: 4,
                              color: "#27ae60",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <PendingIcon
                            style={{
                              fontSize: 18,
                              marginRight: 4,
                              color: "#f39c12",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </Tooltip>
                    );
                  })}
                </div>
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(Object.keys(params?.email_events || {}))
                ? Object.keys(params?.email_events || {})
                : [];
            },
          },
        },

        {
          field: "booking_data.tripDetails.tripEndDateTime",
          headerName: "Checkout Date & Time",
          width: 200,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <Tag>
                  {moment(
                    params?.booking_data?.tripDetails?.tripEndDateTime
                  ).format("DD-MM-YYYY")}
                </Tag>
              );
            },
          },
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
              const cellDate = new Date(cellValue);

              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
          },
        },
        {
          field: "booking_data.segments.confirmationNumber",
          headerName: "Confirmation Number",
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <Tag>{params?.booking_data?.segments?.confirmationNumber}</Tag>
              );
            },
          },
        },

        {
          field: "booking_data.identification.recordLocator",
          headerName: "Booking ID",
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <Tag>{params?.booking_data?.identification?.recordLocator}</Tag>
              );
            },
          },
        },
        {
          field: "booking_data.tripDetails.bookingDateTime",
          headerName: "Booking Date",
          width: 200,
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return moment(
                params.booking_data?.tripDetails?.bookingDateTime
              ).format("DD-MM-YYYY");
            },
          },
          columnGroupShow: "open",
        },
        {
          field: "booking_data.tripDetails.tripStartDateTime",
          headerName: "Checkin Date & Time",
          width: 200,
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return moment(
                params.booking_data?.tripDetails?.tripStartDateTime
              ).format("DD-MM-YYYY");
            },
          },
          columnGroupShow: "open",
        },

        {
          field: "travellers",
          headerName: "Travellers",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {params?.booking_data?.travelers?.map((traveler: any) => {
                    return (
                      <Tag>{`${traveler?.name.middleName} ${traveler?.name.firstName} ${traveler?.name.lastName}`}</Tag>
                    );
                  })}
                </div>
              );
            },
          },
        },
      ],
    },

    {
      headerName: "Customer Details",
      children: [
        {
          field: "gstin_detail.company_name",
          headerName: "Mail Sent To Traveller",
          enableRowGroup: true,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              let travellerEmail =
                params?.booking_data?.contacts?.emailAddresses?.[0]
                  ?.emailAddress;
              let sentEmails =
                params?.email_events?.CHECKOUT_2?.delivered?.map(
                  (emails: any) => emails.email
                ) || [];

              return (
                <Tag
                  icon={
                    sentEmails.includes(travellerEmail.toLowerCase()) ? (
                      <CheckCircleFilled />
                    ) : (
                      <CloseCircleOutlined />
                    )
                  }
                  color={
                    sentEmails.includes(travellerEmail.toLowerCase())
                      ? "green"
                      : "red"
                  }
                >
                  {sentEmails.includes(travellerEmail.toLowerCase())
                    ? "Yes"
                    : "No"}
                </Tag>
              );
            },
            valueGetterData: (data: any) => {
              let travellerEmail =
                data?.booking_data?.contacts?.emailAddresses?.[0]?.emailAddress;
              let sentEmails =
                data?.email_events?.CHECKOUT_2?.delivered?.map(
                  (emails: any) => emails.email
                ) || [];

              return sentEmails.includes(travellerEmail.toLowerCase())
                ? "Yes"
                : "No";
            },
          },
        },
        {
          field: "gstin_detail.gstin",
          headerName: "Customer GSTIN",
          width: 250,
          enableRowGroup: true,
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return <Tag color="blue">{params?.gstin_detail?.[0]?.gstin}</Tag>;
            },
          },
          columnGroupShow: "close",
        },
        {
          field: "gstin_detail.company_name",
          headerName: "Customer Name",
          width: 300,
          enableRowGroup: true,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <Tag color="blue">
                  {params?.gstin_detail?.[0]?.company_name}
                </Tag>
              );
            },
          },
        },

        {
          field: "gstin_detail.[0].address",
          headerName: "Customer Address",
          width: 200,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) =>
              params?.gstin_detail?.[0]?.address,
          },
        },

        {
          field: "gstin_detail.[0].address",
          headerName: "Customer State",
          width: 200,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) =>
              params?.gstin_detail?.[0]?.state_short_name,
          },
        },
      ],
    },
    {
      headerName: "Fare Details",
      children: [
        {
          field: "booking_data.segments.fare.estimatedTotalRate.amount",
          headerName: "Total Fare",
          width: 200,
          columnGroupShow: "close",
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          enableValue: true,
          // filter: "agNumberColumnFilter",
          enableRowGroup: true,
        },
        {
          field: "booking_data.segments.fare.estimatedTotalRate.amount",
          headerName: "Total GST Amount",
          width: 200,
          columnGroupShow: "close",
          enableValue: true,
          filter: "agNumberColumnFilter",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          valueGetter: (params: any) =>
            getGSTCalculated(
              params.data?.booking_data?.segments?.fare?.estimatedTotalRate
                .amount || params.value
            ),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div style={{ textAlign: "right", width: "100%" }}>
                  <FormattedNumber
                    value={getGSTCalculated(data)}
                    style="currency"
                    currency="INR"
                  />
                </div>
              );
            },
          },
        },
        {
          field: "booking_data.segments.fare.rate.amount",
          headerName: "Booking Rate / Day",
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "booking_data.segments.fare.rate.amount",
          headerName: "Tax Rate",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              let totalAmount =
                params?.booking_data?.segments?.fare?.estimatedTotalRate.amount;
              return totalAmount < 1000
                ? "NIL"
                : totalAmount < 7500
                ? "12%"
                : "18%";
            },
          },
          columnGroupShow: "open",
        },
        {
          field: "booking_data.segments.fare.rateCode",
          headerName: "Rate Code",
          columnGroupShow: "open",
          ...handleCellRenderer(),
        },
        {
          field: "booking_data.segments.fare.rateDescription",
          headerName: "Rate Code Description",
          width: 240,
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "booking_data.segments.fare.rateChangeDuringStay",
          headerName: "Rate Change During Stay",
          columnGroupShow: "open",
          ...handleCellRenderer(),
        },
        {
          field: "booking_data.segments.fare.rateChangeDuringStay",
          headerName: "Rate Change Description",
          columnGroupShow: "open",
          ...handleCellRenderer(),
          formatType: "CUSTOM_RENDER",

          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {(
                    params?.booking_data?.segments?.fare?.rateChangeInfos || []
                  ).map((rateChange: any, key: any) => {
                    return (
                      <div
                        key={key}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="xs"
                          style={{
                            color: colorPicker("neutral.800"),
                            marginRight: 6,
                          }}
                        >
                          {rateChange.beginDateTime ? "Date Change" : ""}
                        </Typography>
                        {rateChange.beginDateTime ? (
                          <>
                            <Tag>
                              {moment(rateChange.beginDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </Tag>
                            {" - "}{" "}
                            <Tag>
                              {moment(rateChange.endDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </Tag>
                          </>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              );
            },
          },
        },
        {
          field: "booking_data.segments.fare.commission.percent",
          headerName: "Comission",
          width: 300,
          enableRowGroup: true,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          // valueGetter: (params: any) =>
          //   params.data?.booking_data?.segments?.fare?.commission?.percent
          //     ? `${params.data?.booking_data?.segments?.fare?.commission?.percent}%`
          //     : "NA",
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {params?.booking_data?.segments?.fare?.commission?.percent
                    ? `${params?.booking_data?.segments?.fare?.commission?.percent}%`
                    : "NA"}
                </div>
              );
            },
          },
        },
      ],
    },

    {
      field: "gstin",
      headerName: "GSTIN Match Logic",
      width: 340,
      formatType: "CUSTOM_RENDER",
      ...handleCellRenderer(),
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          return (
            <div
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              <Tag>Hotel_Details.Hotel_State</Tag> ==
              <Tag>Customer_Details.State</Tag>
            </div>
          );
        },
      },
    },

    {
      headerName: "Email Event Logs",
      children: [
        {
          field: "booking_events",
          headerName: "Booking Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "processed",
              "dropped",
              "deferred",
              "bounce",
              "delivered",
              "open",
              "click",
            ],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(
                params?.email_events?.BOOKING || null
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(
                Object.keys(params?.email_events?.BOOKING || {})
              )
                ? Object.keys(params?.email_events?.BOOKING || {})
                : [];
            },
          },
        },

        {
          field: "checkin_events",
          headerName: "Checkin Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "processed",
              "dropped",
              "deferred",
              "bounce",
              "delivered",
              "open",
              "click",
            ],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(
                params?.email_events?.CHECKIN || null
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(
                Object.keys(params?.email_events?.CHECKIN || {})
              )
                ? Object.keys(params?.email_events?.CHECKIN || {})
                : [];
            },
          },
        },
        {
          field: "checkout_events",
          headerName: "Checkout Email",
          width: 300,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "processed",
              "dropped",
              "deferred",
              "bounce",
              "delivered",
              "open",
              "click",
            ],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(params?.email_events?.COMPLETED);
            },
            valueGetterData: (params: any) => {
              return Array.isArray(
                Object.keys(params?.email_events?.COMPLETED || {})
              )
                ? Object.keys(params?.email_events?.COMPLETED || {})
                : [];
            },
          },
        },
        {
          field: "checkin_date",
          headerName: "Monthly Report Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          cellRenderer: (params: any) => {
            return handleRenderEmailEvents(
              params.data?.email_events?.MONTHLY_REPORT
            );
          },
        },
        {
          field: "checkin_date",
          headerName: "Before GSTR Filling Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),

          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(params?.email_events?.BEFORE_GSTR);
            },
          },
        },
        {
          field: "checkin_date",
          headerName: "After GSTR 2B Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(params?.email_events?.AFTER_GSTR);
            },
          },
        },
      ],
    },

    {
      field: "sendmail",
      headerName: "Invoice",
      formatType: "CUSTOM_RENDER",
      ...handleCellRenderer(),
      enableRowGroup: true,
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          return params?.invoice_link || data === "Available" ? (
            <Tag
              color="green"
              icon={<DownloadOutlined />}
              onClick={() => handleOpenInvoices(params?.invoice_link)}
              style={{ cursor: "pointer" }}
            >
              Download
            </Tag>
          ) : (
            <Tag color="yellow" icon={<EllipsisOutlined />}>
              Pending
            </Tag>
          );
        },
        valueGetterData: (params: any) =>
          params?.invoice_link ? "Available" : "Pending",
      },
    },
    {
      field: "sendmail",
      headerName: "Manual Action",
      formatType: "CUSTOM_RENDER",
      ...handleCellRenderer(),
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          let checkoutDay = moment().isAfter(
            moment(params?.booking_data?.tripDetails?.tripEndDateTime)
          );
          let checkinDay = moment().isAfter(
            moment(params?.booking_data?.tripDetails?.tripStartDateTime)
          );

          return params?.status === "COMPLETED" ? null : checkinDay ||
            checkoutDay ? (
            <Button
              size="small"
              type="primary"
              onClick={() => handleTriggerMail(params)}
            >
              {CUSTOM_MAIL_SEND_ACTIONS[params?.status]}
            </Button>
          ) : null;
        },
      },
    },
  ];

  useEffect(() => {
    fetchBookingData();
  }, []);

  const fetchBookingData = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_HOTEL_BOOKING);

    if (response.status) {
      setBookingList(response.data);
    }
    setLoading(false);
  };

  const renderTemplate = (htmlContent: any, data: any) => {
    const templateCompiler = Handlebars.compile(htmlContent);
    const renderedContent = templateCompiler(data);

    return renderedContent;
  };

  const getTemplateDynamicData = (data: any) => {
    let tempData: any = {
      invoice_upload_link: `http://localhost:3000/hotel_booking/upload?ref=${"tessToken"}`,
      bcd_logo:
        "https://www.bcdgroup.com/wp-content/uploads/2019/07/BCD-Travel-Logo-300x109.png",
      novartis_logo:
        "https://static.wixstatic.com/media/7b0ae8_8f33b9fa9a7640078ede15059a3193d6~mv2.png/v1/fill/w_560,h_198,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7b0ae8_8f33b9fa9a7640078ede15059a3193d6~mv2.png",
      subject: "⏰ GST Details for generating invoice",
      acknowledge_mail_url: `https://stage-apiplatform.finkraftai.com/api/webhook/sendgrid/email/ack/${"tessToken"}`,
      upload_invoice_url: `https://hotel.finkraftai.com/hotel_booking/upload?ref=${"tessToken"}`,
      //@ts-ignore
      booking_activity: hotelBookingActivityType[data?.status || "PENDING"],
      entity: {
        name: data?.gstin_detail[0].company_name,
        gstin: data?.gstin_detail[0].gstin,
        address: data?.gstin_detail[0].address,
      },
      hotel_details: {
        hotel_name: data?.booking_data.segments.title,
      },
      booking_details: {
        confirmation_number: data?.booking_data.segments.confirmationNumber,
      },
      travelinfo: {
        travelers: [],
        contact_persons: "Ambuj",

        travelers_all_string: "",
        hotel: [
          {
            confirmationNumber: data?.booking_data?.segments.confirmationNumber,
            name: data?.booking_data?.segments.title,
            property_code: data?.booking_data?.segments.property.code,
            address: `${data?.booking_data?.segments.property.address.address1} ${data?.booking_data?.segments.property.address.city.name} ${data?.booking_data?.segments.property.address.region.code}  ${data?.booking_data?.segments.property.address.postalCode}`,
          },
        ],
        checkin_date: moment(
          data?.booking_data.tripDetails.tripStartDateTime
        ).format("DD/MM/YYYY"),
        booking_date: moment(
          data?.booking_data.tripDetails.bookingDateTime
        ).format("DD/MM/YYYY"),
        checkout_date: moment(
          data?.booking_data.tripDetails.tripEndDateTime
        ).format("DD/MM/YYYY"),
      },
    };

    data?.booking_data.travelers.forEach((traveler: any, key: any) => {
      tempData.travelinfo.travelers.push({
        name: `${traveler.name.middleName} ${traveler.name.firstName} ${traveler.name.lastName}`,
        id: key + 1,
      });
    });

    console.log("template data:", data, tempData);

    return tempData;
  };

  const renderModalContent = () => {
    switch (showModal) {
      case "ADD_HOTEL":
        return (
          <HotelDataUpdate
            bookingData={selectedBooking}
            onSuccess={() => {
              setShowModal("");
              fetchBookingData();
            }}
            type="HOTEL"
          />
        );
      case "ADD_CC":
        return (
          <HotelDataUpdate
            bookingData={selectedBooking}
            onSuccess={() => {
              setShowModal("");
              fetchBookingData();
            }}
            type="CC"
          />
        );
      case "TEMPLATE_VIEW":
        return (
          <div>
            <Tag style={{ marginBottom: 12 }} color="green">
              {templateViewMap[viewTemplate || 0]?.title}
            </Tag>
            <div
              style={{ height: "70vh", overflowY: "scroll" }}
              dangerouslySetInnerHTML={{
                __html: renderTemplate(
                  templateViewMap[viewTemplate || 0]?.data || "",
                  getTemplateDynamicData(templateRowData)
                ),
              }}
            ></div>
          </div>
        );
    }
  };
  return (
    <div className="HotelBookingView">
      <div className="HeaderContainer">
        <PageHeader title="Hotel Email Reports" noAuth />
      </div>
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              flex: 1,
            }}
          >
            <AgTableClient
              // @ts-ignore
              rowData={bookingList}
              columnDefs={initialColDef}
              groupPanel
              // autoResize
              // autoGroupColumnDef={["Vendor Name"]}
              showStatusBar
              showExport
            />
          </div>
        )}
      </div>
      <Modal
        title={modalTitleMap[showModal] || "Modal"}
        open={showModal !== ""}
        onCancel={() => setShowModal("")}
        footer={false}
        width={showModal === "TEMPLATE_VIEW" ? 800 : 500}
      >
        {renderModalContent()}
      </Modal>

      {contextHolder}
    </div>
  );
}
