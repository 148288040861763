import React from "react";
import "./FlightReports.scss";
import PageHeader from "app/shared/PageHeader";
import { AgTableClient } from "app/shared/AgTable";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import EmptyState from "app/shared/EmptyState";
export default function FlightReports(props: any) {
  const [userDetails, _] = useRecoilState<any>(userInfo);

  return (
    <div className="FlightReports" style={{ height: "100%" }}>
      {userDetails?.currentWorkspace?.name === "MMT Admin" ||
      userDetails?.currentWorkspace?.meta?.zoho_workspace_name ? (
        <iframe
          height="100%"
          style={{ width: "100%" }}
          scrolling="no"
          title="Iframe"
          src={`https://analytics.zoho.in/open-view/103074000028594607/3d6f89a2def22c555a0bd0b27ea94869${
            userDetails?.currentWorkspace?.name === "MMT Admin"
              ? ``
              : `?ZOHO_CRITERIA="workspace"%3D%27${userDetails?.currentWorkspace?.meta?.zoho_workspace_name}%27`
          }`}
        ></iframe>
      ) : (
        <EmptyState />
      )}
    </div>
  );
}
