import { Breadcrumb, Button, Empty, Modal, message } from "antd";
import PageHeader from "app/shared/PageHeader";
import { useEffect, useRef, useState } from "react";
import {
  FolderOutlined,
  CloseCircleFilled,
  PlusOutlined,
  FileOutlined,
  CloudUploadOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { AgTableClient } from "app/shared/AgTable";
import Loader from "app/shared/Loader";
import { apiGet, apiPost, apiPostForm } from "app/services/apiServices";
import { Document, Page } from "react-pdf";

import {
  API_ENDPOINT_STORAGE_S3_ADD_FOLDER,
  API_ENDPOINT_STORAGE_S3_LIST,
  API_ENDPOINT_STORAGE_S3_READ_FILE,
  API_ENDPOINT_STORAGE_S3_UPLOAD,
} from "../../invoices.constants";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import TextField from "app/shared/TextField";
import Uploader from "app/shared/Uploader";
import CustomReconTabs from "app/scenes/Reconcilation/components/CustomReconTabs";
//@ts-ignore

function InvoiceExpComponent(props) {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState("");
  const [folderName, setFolderName] = useState("");
  const [pdfData, setPDFData] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const openSidebarRenderer = (params) => {
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };
  const defaultBreds = {
    title: (
      <Typography
        variant="caption"
        style={{ color: colorPicker("neutral.800"), cursor: "pointer" }}
        onClick={() => {
          setBreads([defaultBreds]);
          fetchDirectories("/");
        }}
      >
        Root Directory
      </Typography>
    ),
  };
  const [breads, setBreads] = useState([
    {
      title: (
        <Typography
          variant="caption"
          style={{ color: colorPicker("neutral.800"), cursor: "pointer" }}
          onClick={() => {
            setBreads([defaultBreds]);
            fetchDirectories("/");
          }}
        >
          Root Directory
        </Typography>
      ),
      value: "",
    },
  ]);

  useEffect(() => {
    fetchDirectories();
  }, []);
  const fetchDirectories = async (directory = "/") => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_STORAGE_S3_LIST +
        (directory === "/" ? "" : "?directory=" + directory)
    );
    if (response.status) {
      setTableData(response.data);
    }
    setLoading(false);
  };

  const handleAddFolder = async () => {
    let path = "";
    breads.forEach((bread) => {
      path += bread.value ? bread.value + "/" : "";
    });
    const response = await apiPost(API_ENDPOINT_STORAGE_S3_ADD_FOLDER, {
      folderName: folderName,
      path: path,
    });
    console.log(response);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      setShowModal("");
      fetchDirectories(path);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  function removeObjectsAfterKey(arr, keyValue) {
    const index = arr.findIndex((obj) => obj.key === keyValue);

    if (index !== -1) {
      return arr.slice(0, index + 1);
    }
    console.log("index is", index);

    return arr;
  }

  const handleNavigateBread = (breadInfo) => {
    let removedArray = removeObjectsAfterKey(breads, breadInfo);
    console.log("bread info", removedArray);

    // let path = "";
    // removedArray.forEach((bread) => {
    //   path += bread.value ? bread.value + "/" : "";
    // });
    // setBreads(removedArray);
    // fetchDirectories(path);
  };

  const handleUploadFile = async (file) => {
    console.log("received file", file.name);
    let path = "";
    breads.forEach((bread) => {
      path += bread.value ? bread.value + "/" : "";
    });

    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append("path", path);

    console.log("formdata", formData);

    const response = await apiPostForm(
      API_ENDPOINT_STORAGE_S3_UPLOAD,
      formData
    );
    console.log(response);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      setShowModal("");
      fetchDirectories(path);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const handleReadFile = async (fileKey) => {
    let path = "";
    breads.forEach((bread) => {
      path += bread.value ? bread.value + "/" : "";
    });

    setPDFData(path + fileKey);
    props.onPdfView(path + fileKey);

    // setShowModal("PDF");
  };
  const columnDefs = [
    {
      headerName: "Name",
      field: "Key",
      cellRenderer: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (params.data.type === "folder") {
                fetchDirectories(params?.data.Key + "/");
                setBreads([
                  ...breads,
                  {
                    value: params.data.Key,
                    title: (
                      <Typography
                        variant="caption"
                        style={{
                          color: colorPicker("neutral.800"),
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleNavigateBread(params.data.Key);
                        }}
                      >
                        {params.data.Key}
                      </Typography>
                    ),
                  },
                ]);
              } else {
                handleReadFile(params.data.Key);
              }
            }}
          >
            {params.data.type === "folder" ? (
              <FolderOutlined style={{ marginRight: 6 }} />
            ) : (
              <FileOutlined style={{ marginRight: 6 }} />
            )}
            {params?.data.Key}
          </div>
        );
      },
    },
    {
      headerName: "Last Modified",
      field: "LastModified",
      formatType: "DATE",
    },
    {
      headerName: "Size",
      field: "Size",
      formatType: "FILE_SIZE",
    },
  ];

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  console.log(
    "pdfData",
    `https://colmapping.s3.ap-south-1.amazonaws.com/${pdfData}`,
    pdfData
  );
  return (
    <div className="ScreenContainer">
      <div className="TableContainer">
        <div style={{ padding: "12px 16px" }}>
          <Breadcrumb items={breads} />
        </div>

        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty description="No Invoice Found" />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={columnDefs}
            footer={false}
          />
        )}
      </div>

      <Modal
        title={
          showModal === "FOLDER"
            ? "Add Folder"
            : showModal === "FILE"
            ? "Upload File"
            : "View File"
        }
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={showModal === "FOLDER" ? 400 : 800}
      >
        <div style={{ marginTop: 24 }}>
          {showModal === "FOLDER" ? (
            <div>
              <TextField
                placeholder="Folder Name"
                height={38}
                onChange={(e) => setFolderName(e.target.value)}
              />
              <div
                style={{
                  marginTop: 24,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  size="small"
                  style={{ marginRight: 24 }}
                  onClick={() => setShowModal("")}
                >
                  Cancel
                </Button>
                <Button size="small" type="primary" onClick={handleAddFolder}>
                  Create
                </Button>
              </div>
            </div>
          ) : showModal === "FILE" ? (
            <Uploader onSelect={handleUploadFile} />
          ) : (
            <iframe
              title="PDF Viewer"
              src={`https://colmapping.s3.ap-south-1.amazonaws.com/${pdfData}`}
              width="100%"
              height="500px"
            ></iframe>
          )}
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}

export default function InvoiceExplorer(props) {
  const tabRef = useRef();
  const [activeKey, setActiveKey] = useState(1);
  const [totalTabs, setTotalTabs] = useState([
    {
      title: "GST Recon",
      id: 1,
      type: "VIEW",
    },
  ]);
  return (
    <div className="ScreenContainer">
      <PageHeader
        leftActions={
          <CustomReconTabs
            activeKey={activeKey}
            title="Invoice Explorer"
            onTabChange={(tabKey) => setActiveKey(tabKey)}
            onTabModify={(tabs) => setTotalTabs(tabs)}
            ref={tabRef}
          />
        }
      />
      <div className="TabContainer">
        {totalTabs.map((tab, key) => {
          return (
            <>
              <div
                style={{
                  height: "100%",
                  display: activeKey === tab.id ? "flex" : "none",
                }}
                key={key}
              >
                {tab.type === "VIEW" ? (
                  <InvoiceExpComponent
                    onPdfView={(pdfData) =>
                      tabRef.current.addTab("PDF", pdfData)
                    }
                  />
                ) : (
                  <iframe
                    title="PDF Viewer"
                    src={`https://colmapping.s3.ap-south-1.amazonaws.com/${tab.viewData}`}
                    width="100%"
                    height="100%"
                  ></iframe>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
