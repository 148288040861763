import "./PrimaryNav.scss";
import Typography from "../Typography";
import FinkraftLogo from "static/images/FinLogo.png";
import {
  LeftOutlined,
  UserAddOutlined,
  SettingOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { colorPicker } from "app/utils/color.helper";
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export default function SubMenu(props: any) {
  const [activeMenu, setActiveMenu] = useState("");
  const navigate = useNavigate();
  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click", e);
    navigate(e.key);
  };
  return (
    <div className="PrimaryNavSubMenu">
      <div className="WorkspaceInfoContainer">
        <div className="LeftContent">
          <div className="IconBox">
            <Typography style={{ color: colorPicker("neutral.800") }}>
              {props.menuItems.title || "Credentials"}
            </Typography>
          </div>
        </div>
        <div className="CollapseAction" onClick={props.onClose}>
          <LeftOutlined />
        </div>
      </div>
      <div className="MenuContainer">
        {props.menuItems &&
          props.menuItems.subMenus.map((menu: any, key: any) => {
            return (
              <div
                className={
                  "MenuItem " + (activeMenu === menu.route ? " active" : "")
                }
                key={key}
                onClick={() => {
                  navigate(menu.route);
                  setActiveMenu(menu.route);
                }}
              >
                {props.iconMap[menu.icon]}{" "}
                <Typography style={{ marginLeft: 6 }} variant="caption">
                  {menu.title}
                </Typography>
              </div>
            );
          })}
      </div>
    </div>
  );
}
