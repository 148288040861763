import PageHeader from "app/shared/PageHeader";
import "./Hotels.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";

import {
  Button,
  Dropdown,
  Input,
  message,
  Modal,
  Select,
  Tabs,
  Tag,
} from "antd";

import {
  API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST,
  API_ENDPOINT_FETCH_EMAIL_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST,
} from "../Credentials/credentials.constants";
import { apiGet, apiPost } from "app/services/apiServices";

import Loader from "app/shared/Loader";
import HotelDashboard from "./components/HotelDashboard";
import HotelRecon from "./components/HotelRecon";
import SearchInput from "app/shared/SearchInput";
import UnReconciledHotel from "../AppTour/components/OnboardUnReconcilied/UnReconciledHotel";
import TableViewManager from "app/shared/TableViewManager";
import { API_ENDPOINT_RECONCILATION_RAISE_RECON_REQUEST } from "../Reconcilation/reconcilation.constants";
import ReportHistory from "../Flights/components/ReportsHistory";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { agTableRowSize } from "app/config/States/agtable";
export default function Hotels(props: any) {
  const [activeTab, setActiveTab] = useState("1");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const reconPageRef: any = useRef();
  const [showModal, setShowModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [creatingRequest, setCreatingRequest] = useState(false);
  const [showModelFor, setShowModalFor] = useState("");
  const [reportName, setReportName] = useState("");
  const [tableRowSize, setTableRowSize] = useRecoilState<any>(agTableRowSize);

  const getSceneItems = () => {
    if (userDetails?.currentWorkspace?.name === "MMT Admin") {
      return [
        { key: "1", label: "Dashboard" },
        {
          key: "2",
          label: "Reconciliation",
        },
      ];
    } else {
      return [
        {
          key: "2",
          label: "Reconciliation",
        },
      ];
    }
  };

  useEffect(() => {
    if (
      activeTab === "1" &&
      userDetails?.currentWorkspace?.name !== "MMT Admin"
    ) {
      setActiveTab("2");
    }
  }, [userDetails?.currentWorkspace]);

  const renderScene = () => {
    switch (activeTab) {
      case "1":
        return <HotelDashboard />;
      case "2":
        return <HotelRecon ref={reconPageRef} moduleId={"HOTEL_RECON"} />;
      case "4":
        return (
          <UnReconciledHotel
            // textQuery={searchQuery}
            singleWorkspace={
              userDetails?.currentWorkspace?.name == "MMT Admin" ? false : true
            }
          />
        );

      default:
        return <HotelDashboard />;
    }
  };

  const handleRaiseReportRequest = async () => {
    setCreatingRequest(true);
    let payload = {
      moduleId: "HOTEL_RECON",
      table_name: "mmt_flight_recon",
      name: reportName,
    };
    const response = await apiPost(
      API_ENDPOINT_RECONCILATION_RAISE_RECON_REQUEST,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "We will notify you via email once report is ready",
      });
    }
    setCreatingRequest(false);
    setShowModal(false);
    setShowModalFor("");
    setReportName("");
  };
  const handleViewReports = async (report: any) => {
    if (report.key === "1") {
      setShowModalFor("CREATE");
    } else {
      setShowModalFor("HISTORY");
    }
    setShowModal(true);
  };

  return (
    <div className="Flights">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <Tabs
              items={getSceneItems()}
              tabBarStyle={{
                borderBottom: "none", // Remove the underline border
                margin: 0,
              }}
              activeKey={activeTab}
              onChange={(tabItem: string) => setActiveTab(tabItem)}
            />
          }
          rightActions={
            activeTab === "2" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Select
                  placeholder="Row Size"
                  variant="filled"
                  size="small"
                  defaultValue={tableRowSize}
                  // value={100}
                  style={{ width: 120, marginRight: 12 }}
                  options={[
                    {
                      label: "100 Rows",
                      value: 100,
                    },
                    {
                      label: "1000 Rows",
                      value: 1000,
                    },
                    {
                      label: "10,000 Rows",
                      value: 10000,
                    },
                    {
                      label: "1,00,000 Rows",
                      value: 100000,
                    },
                  ]}
                  onChange={(rowSize: any) => {
                    setTableRowSize(rowSize);
                  }}
                />

                <TableViewManager
                  moduleId={"HOTEL_RECON"}
                  createView={(viewName: string) =>
                    reconPageRef.current &&
                    reconPageRef.current.createNewView(viewName)
                  }
                  selectView={(viewId: string) =>
                    reconPageRef.current &&
                    reconPageRef.current.loadTableView(viewId)
                  }
                  updateView={(viewId: string) =>
                    reconPageRef.current &&
                    reconPageRef.current.updateTableView(viewId)
                  }
                />

                {/* <Button
                  size="small"
                  style={{ marginLeft: 12 }}
                  type="primary"
                 
                >
                  Export
                </Button> */}

                <Dropdown.Button
                  onClick={() =>
                    reconPageRef.current &&
                    reconPageRef.current.exportDataToExcel()
                  }
                  menu={{
                    items: [
                      { key: "1", label: "Export Full Workspace" },
                      { key: "2", label: "Reports Export History" },
                    ],
                    onClick: handleViewReports,
                  }}
                  size="small"
                  style={{ marginLeft: 24 }}
                  type="primary"
                >
                  Export View
                </Dropdown.Button>
              </div>
            ) : null
          }
        />

        <div className="TableContainer">{renderScene()}</div>
        <Modal
          open={showModal}
          footer={false}
          onCancel={() => setShowModal(false)}
          title={
            showModelFor === "CREATE"
              ? "Create Report Request"
              : "Report Download History"
          }
          width={showModelFor === "CREATE" ? 500 : 800}
        >
          {showModelFor === "CREATE" ? (
            <>
              <Input
                placeholder="Report Name"
                onChange={(e: any) => setReportName(e.target.value)}
                value={reportName}
              />
              <div
                style={{
                  marginTop: 12,
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{ marginRight: 12 }}
                  onClick={() => setShowModal(false)}
                  loading={creatingRequest}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleRaiseReportRequest}
                  type="primary"
                  loading={creatingRequest}
                >
                  Create
                </Button>
              </div>
            </>
          ) : (
            <ReportHistory moduleId="HOTEL_RECON" />
          )}
        </Modal>
      </div>
    </div>
  );
}
