import React, { useState } from "react";
import "./FlightRecon.scss";
import PageHeader from "app/shared/PageHeader";
import { AgTableClient } from "app/shared/AgTable";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";

import JsonData from "./demo.json";
import { useRecoilState } from "recoil";
import { currentPDFData } from "app/config/States/users";
const initialColDef = [
  {
    headerName: "Status",
    children: [
      // {
      //   headerName: "Overall Status",
      //   field: "Overall Status",
      //   enableRowGroup: true,
      // },
      {
        headerName: "BTA Match Status",
        field: "BTA Match Status",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["MATCH", "MISMATCH"], // Predefined values for the selector
        },
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              MATCH: "green",
              MISMATCH: "red",
            },
            icon: {
              MATCH: <CheckCircleFilled />,
              MISMATCH: <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "Invoice Received Status",
        field: "Invoice Received Status",
        enableRowGroup: true,
        formatType: "MATCH",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Invoice Received", "Invoice Not Received"], // Predefined values for the selector
        },
        ...handleCellRenderer(),
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              "Invoice Received": "green",
              "Invoice Not Received": "red",
            },
            icon: {
              "Invoice Received": <CheckCircleFilled />,
              "Invoice Not Received": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "Boking Difference Status",
        field: "Boking Difference Status",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["GST Matched", "Mismatch in GST"], // Predefined values for the selector
        },
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              "GST Matched": "green",
              "Mismatch in GST": "red",
            },
            icon: {
              "GST Matched": <CheckCircleFilled />,
              "Mismatch in GST": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "2A Difference Status",
        field: "2A Difference Status",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["2B GST Matched", "Mismatch in 2B GST"], // Predefined values for the selector
        },
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              "2B GST Matched": "green",
              "Mismatch in GST": "red",
            },
            icon: {
              "GST Matched": <CheckCircleFilled />,
              "Mismatch in 2B GST": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
    ],
  },
  {
    headerName: "Difference",
    children: [
      {
        headerName: "Booking GST Difference",
        field: "Booking GST Difference",
        formatType: "CUSTOM_RENDER",
        ...handleCellRenderer(),
        formatProps: {
          cellRenderer: (data: any, params: any) => {
            return (
              <div
                style={{
                  color:
                    parseInt(params?.["Booking GST Difference"]) < 0
                      ? "#dd5061"
                      : "",
                  fontWeight:
                    parseInt(params?.["Booking GST Difference"]) < 0
                      ? 700
                      : 400,
                }}
              >
                {params?.["Booking GST Difference"]}
              </div>
            );
          },
          valueGetterData: (data: any) => data?.["Booking GST Difference"],
        },
      },
      {
        headerName: "2A -Difference",
        field: "2A -Difference",
        formatType: "CUSTOM_RENDER",
        ...handleCellRenderer(),
        formatProps: {
          cellRenderer: (data: any, params: any) => {
            return (
              <div
                style={{
                  color:
                    parseInt(params?.["2A -Difference"]) < 0 ? "#dd5061" : "",
                  fontWeight:
                    parseInt(params?.["2A -Difference"]) < 0 ? 700 : 400,
                }}
              >
                {params?.["2A -Difference"]}
              </div>
            );
          },
          valueGetterData: (data: any) => data?.["2A -Difference"],
        },
      },
    ],
  },

  {
    headerName: "Booking",
    children: [
      {
        headerName: "Vendor",
        field: "Vendor",
        filter: "agTextColumnFilter",
        formatType: "AIRLINE",
        ...handleCellRenderer(),
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "Bkg Ticket Number",
        field: "Bkg_Ticket Number",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Bkg PNR",
        field: "Bkg_PNR",
        filter: "agTextColumnFilter",
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "Bkg GAL PNR",
        field: "Bkg_GAL PNR",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Bkg Ticket/PNR",
        field: "Bkg_Ticket/PNR",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Bkg Transaction Amount",
        field: "Bkg_Transaction Amount",
        formatType: "AMOUNT",
        ...handleCellRenderer(),
        filter: "agNumberColumnFilter",
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "Bkg Location",
        field: "Bkg_Location",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Bkg Origin",
        field: "Bkg_Origin",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Bkg Booking GST",
        field: "Bkg_Booking GST",
        formatType: "AMOUNT",
        ...handleCellRenderer(),
        enableValue: true,
        filter: "agNumberColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Bkg K3",
        field: "Bkg_K3",
        formatType: "AMOUNT",
        ...handleCellRenderer(),
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "Bkg GST Exempted",
        field: "Bkg_GST Exempted",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Bkg SOTO Status",
        field: "Bkg_SOTO Status",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Booking 2A/2B Status",
        field: "Booking 2A/2B Status",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
    ],
  },

  {
    headerName: "BTA",
    children: [
      {
        headerName: "BTA Type",
        field: "BTA_Type",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "BTA Name as per GST Portal",
        field: "BTA_Name as per GST Portal",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "BTA FY",
        field: "BTA_FY",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "BTA Transaction Date",
        field: "BTA_Transaction Date",
        filter: "agDateColumnFilter",
        formatType: "DATE",
        ...handleCellRenderer(),
        columnGroupShow: "open",
      },
      {
        headerName: "BTA Traveller Name",
        field: "BTA_Traveller Name",
        filter: "agTextColumnFilter",
        formatType: "PERSON",
        ...handleCellRenderer(),
        columnGroupShow: "open",
      },
      {
        headerName: "BTA Booking Customer GSTIN",
        field: "BTA_Booking Customer GSTIN",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "BTA Agency Invoice Number",
        field: "BTA_Agency Invoice Number",
        filter: "agTextColumnFilter",
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "BTA Agency Name",
        field: "BTA_Agency Name",
        enableRowGroup: true,
        formatType: "CUSTOM_RENDER",
        filter: "agTextColumnFilter",
        columnGroupShow: ["open", "close"],

        ...handleCellRenderer(),
        formatProps: {
          cellRenderer: (data: any, params: any) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="https://www.shareicon.net/download/2016/07/24/800807_credit-card_512x512.png"
                  style={{
                    width: 32,
                    height: 32,
                    objectFit: "cover",
                    marginRight: 6,
                  }}
                  alt="vendorLogo"
                />
                {params?.["BTA_Agency Name"]}
              </div>
            );
          },
          valueGetterData: (data: any) => data?.["BTA_Agency Name"],
        },
      },
      {
        headerName: "BTA Trans Type",
        field: "BTA_Trans Type",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
    ],
  },

  {
    headerName: "Invoice",
    children: [
      {
        headerName: "INV Customer GSTIN",
        field: "INV Customer GSTIN",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "INV Name as per GST Portal",
        field: "INV Name as per GST Portal",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "INV Supplier GSTIN",
        field: "INV Supplier GSTIN",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "INV Number",
        field: "INV Number",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "Invoice Date",
        field: "Invoice Date",
        filter: "agDateColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "INV Tax Rate",
        field: "INV Tax Rate",
        filter: "agNumberColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "INV Taxable value",
        field: "INV Taxable value",
        formatType: "AMOUNT",
        ...handleCellRenderer(),
        filter: "agNumberColumnFilter",
        columnGroupShow: "open",

        enableValue: true,
      },
      {
        headerName: "INV CGST",
        field: "INV CGST",
        formatType: "AMOUNT",
        enableValue: true,
        ...handleCellRenderer(),
        filter: "agNumberColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "INV SGST",
        field: "INV SGST",
        formatType: "AMOUNT",
        ...handleCellRenderer(),
        filter: "agNumberColumnFilter",
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "INV IGST",
        field: "INV IGST",
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: "open",

        ...handleCellRenderer(),
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "INV Total GST",
        field: "INV Total GST",
        formatType: "AMOUNT",
        ...handleCellRenderer(),
        filter: "agNumberColumnFilter",
        enableValue: true,
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "INV Total Amount",
        field: "INV Total Amount",
        formatType: "AMOUNT",
        enableValue: true,
        ...handleCellRenderer(),
        filter: "agNumberColumnFilter",
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "Inv Doc Type",
        field: "Inv Doc Type",
        enableRowGroup: true,
        enablePivot: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["INV", "CR", "DB"], // Predefined values for the selector
        },
        columnGroupShow: ["open", "close"],
      },
    ],
  },

  {
    headerName: "2A/2B",
    children: [
      { headerName: "2A/2B Invoice Number", field: "2A/2B Invoice Number" },
      {
        headerName: "Status-2A/2B-Invoice/Note_Date",
        field: "Status-2A/2B-Invoice/Note_Date",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),

        pivot: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Match", "Mismatch", "Not in Invoice"], // Predefined values for the selector
        },
        formatProps: {
          matchLogic: {
            matchColor: {
              Match: "green",
              Mismatch: "red",
              "Not in Invoice": "red",
            },
            icon: {
              Match: <CheckCircleFilled />,
              Mismatch: <CloseCircleFilled />,
              "Not in Invoice": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "Status-2A/2B-Supplier_GSTIN",
        field: "Status-2A/2B-Supplier_GSTIN",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Match", "Mismatch", "Not in Invoice"], // Predefined values for the selector
        },
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              Match: "green",
              Mismatch: "red",
              "Not in Invoice": "red",
            },
            icon: {
              Match: <CheckCircleFilled />,
              Mismatch: <CloseCircleFilled />,
              "Not in Invoice": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "Status-2A/2B-Customer_GSTIN",
        field: "Status-2A/2B-Customer_GSTIN",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        pivot: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Match", "Mismatch", "Not in Invoice"], // Predefined values for the selector
        },
        formatProps: {
          matchLogic: {
            matchColor: {
              Match: "green",
              Mismatch: "red",
              "Not in Invoice": "red",
            },
            icon: {
              Match: <CheckCircleFilled />,
              Mismatch: <CloseCircleFilled />,
              "Not in Invoice": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "2A - 2A / 2B Status",
        field: "2A - 2A / 2B Status",
        enableRowGroup: true,
      },
      {
        headerName: "2A/2B - Total_GST-Master",
        field: "2A/2B - Total_GST-Master",
        formatType: "AMOUNT",
        enableValue: true,
        ...handleCellRenderer(),
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "2A/2B - Invoice Status",
        field: "2A/2B - Invoice Status",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        pivot: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Invoice Received", "Invoice Not Received"], // Predefined values for the selector
        },
        formatProps: {
          matchLogic: {
            matchColor: {
              "Invoice Received": "green",
              "Invoice Not Received": "red",
            },
            icon: {
              "Invoice Received": <CheckCircleFilled />,
              "Invoice Not Received": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
    ],
  },
];

export default function FlightRecon(props: any) {
  const [pdfViewerData, setPdfViewerData] = useState("");
  const [_, setCurrentPDFData] = useRecoilState(currentPDFData);
  const openSidebarRenderer = (params: any) => {
    setCurrentPDFData(params?.data);
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };

  const columnDefs = [
    {
      headerName: "Invoice",
      field: "Invoice_Link",
      filter: "agTextColumnFilter",
      invoiceLink: "html.pdf",
      cellRenderer: ToolParamInvoker,
      cellRendererParams: (params: any) => {
        return {
          label: "View Invoice",
          handleClick: openSidebarRenderer,
          data: params.data?.Invoice_Link || params?.value,
        };
      },
    },

    ...initialColDef,
  ];
  return (
    <div className="FlightRecon" style={{ height: "100%" }}>
      <AgTableClient
        /* @ts-ignore */
        columnDefs={columnDefs}
        rowData={JsonData}
        groupPanel
        enablePdfViewer
        pdfDataKey="Invoice_Link"
      />
    </div>
  );
}
