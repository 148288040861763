import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import Loader from "app/shared/Loader";
import { Empty, Tabs } from "antd";
import Typography from "app/shared/Typography";
import EmptyState from "app/shared/EmptyState";
import { useNavigate } from "react-router-dom";
export default function Dashboard(props: any) {
  const [activeKey, setActiveKey] = useState("1");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    if (!userDetails.is_admin) navigate("/flights");
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [userDetails]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <Tabs
              items={[
                {
                  key: "1",
                  label: "Dashboard",
                },
                {
                  key: "2",
                  label: "Dashboard With Error",
                },
              ]}
              tabBarStyle={{
                borderBottom: "none", // Remove the underline border
                margin: 0,
              }}
              activeKey={activeKey}
              onChange={(tabItem: string) => setActiveKey(tabItem)}
            />
          }
          // leftActions={
          //   <CustomReconTabs
          //     activeKey={activeKey}
          //     title="Report"
          //     onTabChange={(tabKey: any) => setActiveKey(tabKey)}
          //     onTabModify={(tabs: any) =>
          //       setTabView({ ...tabViews, dashboard: tabs })
          //     }
          //     hideAddTab
          //     hideRemove
          //     defaultTabs={getTabView()}
          //     width={200}
          //   />
          // }
        />

        <div className="TableContainer">
          <iframe
            height="100%"
            style={{ width: "100%" }}
            scrolling="no"
            title="Iframe"
            src={
              activeKey === "1"
                ? "https://analytics.zoho.in/open-view/103074000029023867/f37053b66a4e652e63285be2992b8bb9"
                : "https://analytics.zoho.in/open-view/103074000028957085/4958d45a5c947c04a33134eb9b08665d"
            }
          ></iframe>
        </div>
      </div>
    </div>
  );
}
