import { Avatar, Badge, Input, Modal, Popover, Tag, message } from "antd";
import FinkAvatar from "static/images/FinkAvatar.png";
import "./FinkAI.scss";
import { colorPicker } from "app/utils/color.helper";
import { useEffect, useState } from "react";
import {
  CloseOutlined,
  SendOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Typography from "../Typography";
import { v4 as uuidv4 } from "uuid";
import loadingDot from "static/lottie/dotLoading.json";
import Lottie from "react-lottie";
import SpeechToText from "./SpeechToText";
//@ts-ignore
import SureAudio from "static/audio/sure.mp3";
//@ts-ignore
import GotItAudio from "static/audio/goit.mp3";
//@ts-ignore
import OfcourseAudio from "static/audio/ofcourse.mp3";
//@ts-ignore
import HereyouGoAudio from "static/audio/hereyougo.mp3";
// import failedResponseAudio from "static/audio";
import finkLottie from "static/lottie/fink.json";
import { apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_OPEN_AI_CONVERSATION,
  API_ENDPOINT_OPEN_AI_SET_CONTEXT,
} from "global.constants";

const finkPositiveResponse = [
  SureAudio,
  HereyouGoAudio,
  GotItAudio,
  OfcourseAudio,
];

export default function FinkAI(props: any) {
  const [showChat, setShowChat] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [defs, setDefs] = useState<any[]>([]);
  const [chatHistory, setChatHistory] = useState<any[]>([]);
  const API_KEY = "sk-proj-AjF0eNKiecIoUqDU7fZ6T3BlbkFJOQduxJajpdVjuDSA8v2j";
  const API_ENDPOINT = "https://api.openai.com/v1/chat/completions";
  const [chatInProgress, setChatInProgress] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    groupModel: [],
    filterModel: {},
    sortModel: [],
  });

  useEffect(() => {
    getProcessDefinations();
  }, [props.columnDefs]);
  const getProcessDefinations = () => {
    let newArray: any = [];
    (props.columnDefs || []).forEach((columnData: any) => {
      if (columnData.children && columnData.children.length > 0) {
        let nestedColumn: any = {
          header: columnData.headerName,
          children: [],
        };
        columnData.children.forEach((childElement: any) => {
          nestedColumn.children.push({
            field: childElement.field,
            headerName: childElement.headerName,
          });
        });
        newArray.push(nestedColumn);
      } else {
        newArray.push({
          field: columnData.field,
          headerName: columnData.headerName,
        });
      }
    });
    console.log("column defs", newArray);
    setDefs(newArray);
  };

  const referenceObject: any = {
    rowGroupCols: [],
    filterModel: {},
    sortModel: [],
    pivotMode: false,
    pivotCols: [],
    valueCols: [],
  };

  function validateJSONStructure(data: any) {
    if (!data || typeof data !== "object") return false;

    // Check if required keys exist and have correct types
    const hasCorrectStructure =
      data.hasOwnProperty("filterModel") &&
      data.hasOwnProperty("sortModel") &&
      data.hasOwnProperty("pivotMode") &&
      data.hasOwnProperty("rowGroupCols") &&
      data.hasOwnProperty("pivotCols") &&
      data.hasOwnProperty("valueCols") &&
      typeof data.filterModel === "object" &&
      Array.isArray(data.sortModel) &&
      typeof data.pivotMode === "boolean" &&
      Array.isArray(data.rowGroupCols) &&
      Array.isArray(data.pivotCols) &&
      Array.isArray(data.valueCols);

    if (!hasCorrectStructure) {
      return false; // Invalid structure
    }

    // Validate sortModel array structure
    const isSortModelValid = data.sortModel.every(
      (item: any) =>
        typeof item.colId === "string" && typeof item.sort === "string"
    );

    return isSortModelValid;
  }
  useEffect(() => {
    if (defs.length > 0) setDefaultContext();
  }, [defs]);

  const setDefaultContext = async () => {
    const payload = {
      context: ` You are an expert in configuring AG Grid. The user might ask you to auto filter, group, or pivot the data based on column definitions.Here is an example column definition for AG Grid:const columnDefs = 
      ${JSON.stringify(defs)}
       If the user asks to group data by any column or short,filter even pivoting, you should generate the appropriate server-side row model payload for AG Grid in format like this {"filterModel": {},"sortModel": [],"pivotMode": false,"rowGroupCols": [],"pivotCols": [],"valueCols": []} in valid JSON and you can also refer to the data which is used in table for understanding better and giving accurate response ${JSON.stringify(
         props.dataset
       )} but always give result in valid JSON no string nothing.Always keep this in mind while answering questions and If user ask you to clear all filer then return the empty ssr payload and important thing to remember alwasy give only json in repsonse no other text or explaination .`,
    };
    const response = await apiPost(API_ENDPOINT_OPEN_AI_SET_CONTEXT, payload);
  };
  const generateText = async (userInput: string) => {
    let randomNumber = Math.floor(Math.random() * 4) + 1;
    setTimeout(() => {
      const audio = new Audio(finkPositiveResponse[randomNumber - 1]);
      if (audio) audio.play();
    }, 500);

    let chatId = uuidv4();
    setChatHistory((chats) => [
      ...chats,
      ...[
        { role: "USER", text: userInput, id: uuidv4() },
        { role: "FINK", text: "", id: chatId },
      ],
    ]);

    setChatInProgress(chatId);
    setLoading(true);

    setInputPrompt("");

    const response = await apiPost(API_ENDPOINT_OPEN_AI_CONVERSATION, {
      message: userInput,
      context: defs,
    });
    if (response.response) {
      // if (Object.entries(finalData).length > 0) {
      props.onResponse && props.onResponse(response.response);
      setCurrentFilters(response.response);
    }

    setLoading(false);
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingDot,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: finkLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="FinkAI">
      <div className="InitButton" onClick={() => setShowChat(!showChat)}>
        {!showChat ? (
          <>
            <Tag
              color={colorPicker("primary.600")}
              style={{ borderRadius: 25 }}
            >
              <span className="wave-emoji">👋</span> Hi, I am Fink AI
            </Tag>

            {/* <Avatar
              style={{
                backgroundColor: "white",
                boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                border: `2px solid ${colorPicker("primary.600")}`,
              }}
              size={42}
              src={<img src={FinkAvatar} alt="avatar" />}
            /> */}
            <div className="FinkContainer">
              <Lottie options={defaultOptions} height={60} width={60} />
            </div>
          </>
        ) : (
          <Avatar
            style={{
              backgroundColor: colorPicker("primary.700"),
              boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
            }}
            size={34}
            icon={<CloseOutlined style={{ color: "white", fontSize: 20 }} />}
          />
        )}
      </div>

      <div className={"ChatContainer " + (showChat ? " active" : "")}>
        <div className="ChatHeader">
          <div className="HeaderLeft">
            <Popover
              content={
                <div>
                  <Avatar
                    style={{
                      backgroundColor: colorPicker("primary.200"),
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                    }}
                    size={112}
                    src={<img src={FinkAvatar} alt="avatar" />}
                  />
                </div>
              }
            >
              {/* <Avatar
                style={{
                  backgroundColor: colorPicker("primary.200"),
                  boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                  cursor: "pointer",
                }}
                size={34}
                src={<img src={FinkAvatar} alt="avatar" />}
              /> */}
              <Lottie options={defaultOptions} height={42} width={42} />
            </Popover>
            <Typography
              style={{ marginLeft: 6, color: colorPicker("neutral.800") }}
              variant="caption"
            >
              Fink AI
            </Typography>
          </div>
          <div className="HeaderRight" onClick={() => setShowModal(true)}>
            <InfoCircleOutlined
              style={{ fontSize: 14, color: colorPicker("neutral.700") }}
            />
            <Typography
              style={{ marginLeft: 4, color: colorPicker("neutral.700") }}
              variant="xs"
            >
              How it works?
            </Typography>
          </div>
        </div>
        <div className="Chatbody">
          {chatHistory.length > 0 ? null : (
            <div className="ChatLeft">
              <div className="UserAvatar">
                <Avatar
                  style={{
                    backgroundColor: colorPicker("primary.600"),
                    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                  }}
                  size={28}
                >
                  AI
                </Avatar>
              </div>

              <div className="ChatContent">
                <div
                  className="TextContainer"
                  style={{ backgroundColor: colorPicker("primary.50") }}
                >
                  <Typography
                    variant="xs"
                    style={{ color: colorPicker("neutral.800") }}
                  >
                    Hi! I’m fink.ai im here to assist you. What you are looking
                    for?
                    <br />
                    <br />
                    Choose one of these popular filter for the current table.
                  </Typography>
                </div>
                <div className="ActionContainer">
                  {props.defaultActions.map((actionItem: any, key: any) => {
                    return (
                      <div
                        className="ActionItem"
                        style={{
                          border: `1px solid ${colorPicker("primary.700")}`,
                          cursor: "pointer",
                        }}
                        key={key}
                        onClick={() => {
                          setChatHistory([
                            ...chatHistory,
                            { role: "FINK", message: "Vizulatization done" },
                          ]);
                          props.onResponse &&
                            props.onResponse(actionItem.model);
                        }}
                      >
                        <Typography
                          variant="xs"
                          style={{ color: colorPicker("primary.700") }}
                        >
                          {actionItem.name}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {chatHistory.map((chat: any, key: any) => {
            return chat.role === "FINK" ? (
              <div className="ChatLeft" key={key}>
                <div className="UserAvatar">
                  <Avatar
                    style={{
                      backgroundColor: colorPicker("primary.600"),
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                    }}
                    size={28}
                  >
                    AI
                  </Avatar>
                </div>

                <div className="ChatContent">
                  <div
                    className="TextContainer"
                    style={{ backgroundColor: colorPicker("primary.50") }}
                  >
                    <Typography
                      variant="xs"
                      style={{ color: colorPicker("neutral.800") }}
                    >
                      {chatInProgress === chat.id && isLoading ? (
                        <Lottie
                          options={lottieOptions}
                          height={18}
                          width={26}
                        />
                      ) : (
                        <>
                          Visualization done!{" "}
                          {chatInProgress === chat.id ? (
                            <>
                              , not happy?
                              <span
                                style={{
                                  textDecoration: "underline",
                                  marginLeft: 4,
                                }}
                              >
                                Reset
                              </span>
                            </>
                          ) : null}
                        </>
                      )}
                    </Typography>
                  </div>
                </div>
              </div>
            ) : (
              <div className="ChatRight" key={key}>
                <div className="ChatContent">
                  <div
                    className="TextContainer"
                    style={{ backgroundColor: colorPicker("neutral.100") }}
                  >
                    <Typography
                      variant="xs"
                      style={{ color: colorPicker("neutral.800") }}
                    >
                      {chat.text}
                    </Typography>
                  </div>
                </div>
                <div className="UserAvatar">
                  <Avatar
                    style={{
                      backgroundColor: colorPicker("primary.400"),
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                    }}
                    size={28}
                  >
                    T
                  </Avatar>
                </div>
              </div>
            );
          })}
        </div>
        <div className="ChatInputContainer">
          {/* <SpeechToText /> */}
          <Input
            placeholder="Type..."
            suffix={
              <SendOutlined
                style={{ fontSize: 16, color: colorPicker("neutral.700") }}
                // onClick={generateText}
              />
            }
            prefix={
              <SpeechToText
                onSpeech={(text: string) => {
                  setInputPrompt(text);
                  //   generateText(text);
                }}
                currentText={inputPrompt}
                onInactivity={(text: string) => generateText(text)}
              />
            }
            onChange={(e) => setInputPrompt(e.target.value)}
            value={inputPrompt}
            onPressEnter={() => generateText(inputPrompt)}
            style={{
              backgroundColor: colorPicker("neutral.300"),
              border: "none",
              fontSize: 12,
              height: 32,
            }}
          />
        </div>
      </div>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        width={800}
        title="Fink AI Demo"
      >
        <div
          style={{
            height: 500,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <iframe
            width="640"
            height="400"
            src="https://www.loom.com/embed/f1df5fd1df324f03ac59582553fe14a6?sid=3e498049-6bde-402b-a152-445b221d664b"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </div>
  );
}
